import {useQuery} from "react-query";
import axios from "../index";

const name = 'statistics'

const createUseFetchStatistic = (statisticName) => ({electionId}) => {
    return useQuery([name, electionId, statisticName], async () => {
        try {
            return await axios.get(`/election/${electionId}/${name}/${statisticName}`).then((response) => response.data);
        } catch (err) {
            throw err;
        }
    })
}

export const queries = {
    useFetchCandidates: createUseFetchStatistic('candidates'),
    useFetchVotings: createUseFetchStatistic('votings'),
    useFetchSchools: createUseFetchStatistic('schools'),
    useFetchGender: createUseFetchStatistic('gender'),
    useFetchAge: createUseFetchStatistic('age'),
    useDeviceTypes: createUseFetchStatistic('deviceTypes'),
}

