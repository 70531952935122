import React, {useEffect} from "react";
import * as yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {DevTool} from "@hookform/devtools";
import FormCard from "../../components/form/FormCard";
import {Grid} from "@material-ui/core";
import RHFTextField from "../../components/form/RHFTextField";
import FeedbackButton from "../../components/form/FeedbackButton";
import {ActionButton} from "../../layout/ActionButton";
import RHFSwitch from "../../components/form/RHFSwitch";

const schema = yup.object().shape({
    content: yup.string().required('Bitte ausfüllen'),
    active: yup.bool()
});

export const Form = ({snippet, pending, backLink, onSubmit}) => {

    const formMethods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {}
    });

    const {handleSubmit, reset, control} = formMethods;

    useEffect(function() {
        if(snippet !== undefined) {
            reset(snippet)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snippet]);

    return (
        <FormProvider {...formMethods}>
            <DevTool control={control}/>
            <form id="snippetForm" onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormCard title={'Stammdaten'}>
                    <Grid container spacing={6} >

                        <Grid item xs={12}>
                            <p>Bitte beachten, dass z.B. Nummer ohne zusätzliche HTML-Tags oder ähnliches eingetragen werden</p>
                            <RHFTextField
                                name="content"
                                label="Text"
                                multiline
                                minRows={4}
                                maxRows={6}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <RHFSwitch
                                name="active"
                                label="Text anzeigen"
                            />
                        </Grid>

                    </Grid>

                    <Grid container justifyContent={'flex-end'} item xs={12}>

                        <FeedbackButton type="submit" pending={pending}>
                            speichern
                        </FeedbackButton>

                        <ActionButton to={backLink}>
                            Zurück
                        </ActionButton>
                    </Grid>

                </FormCard>
            </form>
        </FormProvider>
    )
}

export default Form