import React from 'react'
import {Link} from "react-router-dom";
import {Button} from "./StyledComponent";

export const ActionButton = ({children, ...props}) => {
    return (
        <Button component={Link} {...props} variant="outlined" color="primary" ml={2}>
            {children}
        </Button>
    )
}

export default ActionButton