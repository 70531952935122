import React from 'react';
import List from '@material-ui/core/List'
import styled from "styled-components";
import PropTypes from "prop-types";
import MenuItem from "./MenuItem";
import {useParams} from "react-router-dom";
import {intersection} from "lodash";

const StyledList = styled(List)`
    margin-top: 20px;
`;

const Menu = ({menuItems}) => {
    const params = useParams()
    const paramKeys = Object.keys(params)

    const items = menuItems.filter(item => {
        if (!('dependsOnParameters' in item)) {
            return true
        }

        const itemParams = intersection(paramKeys, item.dependsOnParameters);
        return itemParams.length !== 0
    })

    return (
        <StyledList component="nav" disablePadding>
            {items.map((item, index) => (
                <MenuItem item={item} key={index}/>
            ))}
        </StyledList>
    )
};
Menu.propTypes = {
    menuItems: PropTypes.arrayOf(PropTypes.object)
};
export default Menu;