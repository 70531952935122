import React from 'react'
import BackendContent from "../../layout/BackendContent";
import LoadingOverlay from "../../layout/LoadingOverlay";
import {useParams} from "react-router-dom";
import {mutations, queries} from "../../api/query/candidate";
import {Toolbar} from "../../layout/toolbar/Toolbar";
import BackButton from "../../layout/ActionButton";
import FeedbackButton from "../../components/form/FeedbackButton";
import Form from "./Form";
import * as moment from "moment";
import ConfirmationButton from "../../layout/ConfirmationButton";

export const UpdateView = () => {
    const {candidateId, electionId} = useParams()
    const {data: candidate, isLoading} = queries.useFetch({electionId, id: candidateId})

    const {mutate: mutateUpdate, isLoading: updating} = mutations.useUpdate()
    const {mutate: mutateDelete, isLoading: deleting} = mutations.useDelete()

    const backLink = `/election/${electionId}`

    return (
        <BackendContent>
            <LoadingOverlay open={isLoading}/>

            <Toolbar title={'Kandidat bearbeiten'}>
                <FeedbackButton form="candidateForm" type="submit" pending={updating}>
                    speichern
                </FeedbackButton>

                <ConfirmationButton
                    onSuccess={() => {
                        mutateDelete({electionId: electionId, id: candidateId})
                    }}
                    ButtonProps={{'ml': 2}}
                    dialogTitle={'Kandidat wirklich löschen?'}
                    dialogText={'Achtung! Falls der Kandidat bereits eine Stimme hat, kann er nicht mehr gelöscht werden.'}
                    pending={deleting}
                >
                    löschen
                </ConfirmationButton>

                <BackButton to={backLink}>
                    Zur Liste
                </BackButton>
            </Toolbar>

            {candidate && <Form
                onSubmit={(formData) => {
                    const data = {
                        ...candidate,
                        ...formData,
                        birthday: moment(formData.birthday).format('YYYY-MM-DD')
                    };
                    mutateUpdate({electionId, data})
                }}
                backLink={backLink}
                pending={updating}
                candidate={candidate}
            />}
        </BackendContent>
    )
}

export default UpdateView