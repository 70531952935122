import { unstable_createMuiStrictModeTheme as createMuiTheme  } from '@material-ui/core/styles';


const primaryMainColorPalette = '#1976d2';
const secondaryMainColorPalette = '#2196f3';
const warningMainColorPalette = '#ff9800';
const errorMainColorPalette = '#f44336';
const infoMainColorPalette = '#2196f3';
const successMainColorPalette = 'rgb(153,204,51)';

export const color = {
    green: 'rgb(153,204,51)'
};

export const themeOptions = {
    spacing: 4,
    typography: {
        fontFamily: [
            'Lato',
            'Open Sans',
            'sans-serif'
        ].join(','),
        h1: {
            fontSize: '1.75rem',
            fontWeight: 600,
            lineHeight: 1.2
        },
        h2: {
            fontSize: '1.5rem',
            fontWeight: 600,
            lineHeight: 1.2
        },
        h3: {
            fontSize: '1.25rem',
            fontWeight: 600,
            lineHeight: 1.2
        },
        h4: {
            fontSize: '1.125rem',
            fontWeight: 600,
            lineHeight: 1.2
        },
        h5: {
            fontSize: '1.0625rem',
            fontWeight: 600,
            lineHeight: 1.2
        },
        h6: {
            fontSize: '1rem',
            fontWeight: 600,
            lineHeight: 1.2
        }
    },
    overrides: {
        // MuiCssBaseline: {
        //     '@global': {
        //         '@font-face': [raleway],
        //     },
        // },
        MuiCard: {
            root: {
                overflow: 'visible'
            }
        },
        MuiDialogContent: {
            root: {
                overflow: 'visible'
            }
        },
        MuiCardHeader: {
            content: {
                '&::after': {
                    marginTop: '5px',
                    maxWidth: 'none !important',
                    borderBottom: '1px solid #ccceef !important'
                }
            }
        },
        // MuiInputLabel: {
        //     root: {
        //         fontSize: '1rem'
        //     },
        //     shrink: {
        //         transform: 'translate(0, 1.5px) scale(0.9)'
        //     }
        // },
        // MuiInputBase: {
        //     root: {
        //         fontSize: '1rem'
        //     }
        // },
        MuiButton: {
            root: {
                textTransform: 'none'
            },
            containedPrimary: {
                color: '#ffffff',
                textTransform: 'none',
                '&.Mui-disabled': {
                    backgroundColor: primaryMainColorPalette,
                    color: '#ffffff'
                }
            }
        },
        MuiTablePagination: {
            spacer: {
                display: 'none'
            }
        },
        // MuiTextField: {
        //     root: {
        //         margin: '8px'
        //     }
        // },
        MuiPaper: {
            elevation1: {
                'box-shadow': '0 0 14px 0 rgba(53,64,82,.20);'
            }
        },
        MuiTypography: {
            body1: {
                fontSize: '0.875rem',
                fontWeight: 400,
                lineHeight: 1.5
            }
        },
        MuiChip: {
            colorPrimary: {
                color: '#ffffff'
            },
            colorSecondary: {
                color: '#ffffff'
            }
        },
        MuiTableCell: {
            head: {
                'font-weight': 600
            }
        },
        MuiBadge: {
            colorSecondary: {
                color: '#ffffff'
            }
        },
        MuiTabs: {
            root: {
                backgroundColor: '#ffffff'
            }
        }
        // MuiAlert: {
        //     filledSuccess: {
        //         color: 'rgb(30, 70, 32)',
        //         'background-color': 'rgb(237, 247, 237)'
        //     }
        // }
    },
    props: {
        MuiButtonBase: {
            disableRipple: true
        },
        MuiButton: {
            disableRipple: true,
            disableElevation: true
        },
        MuiTextField: {
            fullWidth: true
        },
        MuiFormControl: {
            fullWidth: true
        },
        MuiTypography: {
            gutterBottom: false
        },
        MuiChip: {
            size: 'small'
        }
    },
    palette: {
        primary: {
            main: primaryMainColorPalette
        },
        secondary: {
            main: secondaryMainColorPalette
        },
        error: {
            main: errorMainColorPalette
        },
        warning: {
            main: warningMainColorPalette
        },
        info: {
            main: infoMainColorPalette
        },
        success: {
            main: successMainColorPalette
        }
    }
};

export default createMuiTheme(themeOptions);