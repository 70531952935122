import React from 'react'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Controller, useFormContext} from "react-hook-form";
import RHFErrorMessage from "./RHFErrorMessage";
import MomentUtils from "@date-io/moment";
import moment from "moment";

export const RHFDatePicker = ({name, defaultValue, ...rest}) => {
    const {control, formState: {errors}} = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue || moment()}
            render={({
                         field: {onChange, onBlur, value, name, ref},
                         fieldState: {invalid, isTouched, isDirty, error},
                         formState
                     }) => (
                <>
                    <MuiPickersUtilsProvider utils={MomentUtils} locale={'de'}>
                        <DatePicker
                            autoOk={true}
                            inputVariant={'outlined'}
                            value={value}
                            onChange={(val) => onChange(moment(val).format('YYYY-MM-DD'))}
                            {...rest}
                        />
                    </MuiPickersUtilsProvider>
                    <RHFErrorMessage errors={errors} name={name}/>
                </>
            )}
        />
    )
}

export default RHFDatePicker