import React, {useState} from 'react'

import {FileUploadList} from "./FileUploadList";
import {FileInput} from "../file/FileInput";
import {useMutation} from "react-query";
import axios from "../../api";
import Deferred from "../../util/promise";
import {toast} from "react-hot-toast";
import createUploadFile, {UPLOAD_STATE} from "./factory";

export const FileUploadInput = ({name, path, onComplete, onRemove, clearAfterUpload = false, toastOptions = {}, ...rest}) => {

    const [uploadFileState, setUploadFileState] = useState({});

    const {mutate: mutateUpload } = useMutation(async ({files}) => {

        const uploadFiles = {};
        Array.from(files).forEach(file => {
            const uploadFile = createUploadFile(file)
            return uploadFiles[uploadFile.id] = uploadFile
        })

        setUploadFileState(uploadFiles)

        return Promise.all(Object.values(uploadFiles).map((uploadFile) => {

            const formData = new FormData();
            formData.append("file", uploadFile.file, uploadFile.name);

            try {
                return axios.post(`${path}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (event) => {
                        const progress = Math.round(event.lengthComputable ? event.loaded * 100 / event.total : 0);
                        const secondsElapsed = (new Date().getTime() - uploadFile.createdAt.getTime()) / 1000;
                        const remainingBytes = event.total - event.loaded;
                        const bytesPerSecond = Math.floor(event.loaded / secondsElapsed);
                        const secondsRemaining = Math.floor(remainingBytes / bytesPerSecond);

                        if (progress >= 100) {
                            uploadFile.status = UPLOAD_STATE.COMPLETE
                        }

                        setUploadFileState( prevData => {
                            return {
                                ...prevData,
                                [uploadFile.id]: {
                                    ...uploadFile,
                                    progress,
                                    secondsRemaining,
                                    remainingBytes
                                }
                            }
                        });
                    }
                }).then((response) => {
                    uploadFile.response = response.data;

                    setUploadFileState( prevData => {
                        prevData[uploadFile.id] = uploadFile
                        return prevData
                    });

                    return {
                        response: response.data,
                        uploadFile
                    }
                });
            } catch (err) {
                throw err;
            }
        }));
    }, {
        onMutate: async ({files}) => {
            const toastPromise = new Deferred();
            const fileCount = files.length

            toast.promise(toastPromise, {
                loading: `Es sollen ${fileCount} Datei(en) hochgeladen werden`,
                success: "Die Datei(en) wurden erfolgreich hochgeladen",
                error: "Die Datei(en) konnten nicht hochgeladen werden",
                ...toastOptions
            });

            return {toastPromise}
        },
        onSuccess: (data, variables, context) => {
            onComplete(data)
            context.toastPromise.resolve()
            if(clearAfterUpload) {
                setUploadFileState({})
            }
        },
        onError: ((error, variables, context) => {
            context.toastPromise.reject()
        })
    })

    const handleChange = (files) => {
        mutateUpload({files})
    }

    const handleRemove = (uploadFile) => {

        setUploadFileState(prevState => {
            delete prevState[uploadFile.id]
            return prevState
        })

        if(onRemove) {
            onRemove(uploadFileState, uploadFile)
        }
    }

    return (
        <>
            <FileInput name={`${name}FileInput`} onChange={handleChange} {...rest} />
            {<FileUploadList files={uploadFileState} onRemove={handleRemove}/>}
        </>
    )
}