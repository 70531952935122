import React from "react";
import {ErrorMessage} from "@hookform/error-message";
import {FormHelperText} from "@material-ui/core";

/**
 * @param name
 * @param errors
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
const RHFErrorMessage = ({name, errors, ...rest}) => {

    return <ErrorMessage
        name={name}
        errors={errors}

        render={({ message }) => {
            return <FormHelperText
                error={true}
            >
                {message}
            </FormHelperText>
        }}
        {...rest}
    />
}
export default RHFErrorMessage;