import React from 'react'
import * as yup from "yup";
import {DevTool} from "@hookform/devtools";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Grid} from "@material-ui/core";
import RHFSwitch from "../../components/form/RHFSwitch";
import FormCard from "../../components/form/FormCard";
import FeedbackButton from "../../components/form/FeedbackButton";

const schema = yup.object().shape({
    showCandidates: yup.bool()
})

export const Form = ({election, onSubmit, pending}) => {

    const formMethods = useForm({
        resolver: yupResolver(schema),
        defaultValues: election
    });

    const {handleSubmit, control} = formMethods;

    if(election === undefined) {
        return null
    }

    return (
        <FormProvider {...formMethods}>
            <DevTool control={control}/>
            <form id="electionForm" onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormCard title={'Wahl Einstellungen'}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <RHFSwitch
                                name="showCandidates"
                                label="Zeige alle Kandidaten an"
                            />
                        </Grid>

                    </Grid>

                    <Grid container justifyContent={'flex-end'} item xs={12}>

                        <FeedbackButton type="submit" pending={pending}>
                            speichern
                        </FeedbackButton>
                    </Grid>
                </FormCard>
            </form>
        </FormProvider>
    )
}

export default Form