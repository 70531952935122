import React, {useState} from 'react';
import {
    CardContent, Grid, Card
} from "@material-ui/core";
import LoadingOverlay from "../../layout/LoadingOverlay";
import {ServerPagination} from "../../components/list/Pagination";

import BackendContent from "../../layout/BackendContent";

import {Toolbar} from "../../layout/toolbar/Toolbar";
import {ElectionTable} from "./ElectionTable";
import {queries} from "../../api/query/election";



const ListView = () => {
    const [query, setQuery] = useState({});

    const {data: elections, isLoading, pagination} = queries.useList(query)

    return <BackendContent>
        <LoadingOverlay open={isLoading}/>

        <Toolbar title={'Wahlen'} />

        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Card mb={6}>
                    <CardContent>
                        <ElectionTable
                            elections={elections}
                            pagination={
                                <ServerPagination data={pagination} colSpan={3} onChangePage={(event, newPage) => {
                                    setQuery({
                                        limit: pagination.maxPerPage,
                                        offset: newPage + 1
                                    })
                                }}/>
                            }
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </BackendContent>;
};

export default ListView;