import React from "react";
import {FormProvider, useForm} from "react-hook-form";
import {Grid} from "@material-ui/core";
import FeedbackButton from "../../components/form/FeedbackButton";
import {DevTool} from "@hookform/devtools";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import RHFTextField from "../../components/form/RHFTextField";

const schema = yup.object().shape({
    email: yup.string().required('Bitte ausfüllen'),
    password: yup.string().required('Bitte ausfüllen')
});


const Form = ({onSubmit, pending }) => {

    const formMethods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: '',
            password: ''
        }
    });

    const {handleSubmit, control} = formMethods;

    return <FormProvider {...formMethods}>
        <DevTool control={control} />
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={4}>

                <Grid item xs={12}>
                    <RHFTextField name="email" label="E-Mail" />
                </Grid>

                <Grid item xs={12}>
                    <RHFTextField name="password" label="Passwort" type="password" />
                </Grid>

                <Grid item xs={12}>

                    <FeedbackButton type="submit" variant={'contained'} color={"primary"} fullWidth={true} pending={pending} mt={2}>
                        einloggen
                    </FeedbackButton>
                </Grid>
            </Grid>
        </form>
    </FormProvider>

}

export default Form;