import {Switch} from "react-router-dom";
import LayoutRoute from "../../layout/router/LayoutRoute";
import BackendArea from "../../layout/templates/BackendArea";
import MiddleContent from "../../layout/templates/MiddleContent";
import Error404 from "../system/Error404";
import ListView from "./ListView";
import UpdateView from "./UpdateView";

const Routing = () => {
    return (
        <>
            <Switch>
                <LayoutRoute layout={BackendArea} exact path="/election/:electionId/snippet/:snippetId/update" component={UpdateView}/>
                <LayoutRoute layout={BackendArea} exact path="/election/:electionId/snippet" component={ListView}/>

                <LayoutRoute path="*" layout={MiddleContent} component={Error404}/>
            </Switch>
        </>
    )
};
export default Routing;