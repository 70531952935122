import React from 'react'
import BackendContent from "../../layout/BackendContent";
import LoadingOverlay from "../../layout/LoadingOverlay";
import {Toolbar} from "../../layout/toolbar/Toolbar";
import VotingsStatistic from "./VotingsStatistic";
import {queries} from "../../api/query/statistics";
import {useParams} from "react-router-dom";
import StatisticHeader from "./StatisticHeader";
import {Typography} from "@material-ui/core";

export const VotingView = () => {
    const {electionId} = useParams()
    const {data: votingsStatistics, isLoading} = queries.useFetchVotings({electionId})

    return (
        <BackendContent>
            <LoadingOverlay open={isLoading}/>
            <Toolbar title={'Statistiken'} />
            <StatisticHeader />
            <Typography variant="h4" gutterBottom component="div">Stimmabgabe Zeitraum</Typography>
            <VotingsStatistic statistic={votingsStatistics} />

        </BackendContent>
    )
}

export default VotingView