import React from 'react'
import {mutations} from "../../api/query/candidate";
import {Toolbar} from "../../layout/toolbar/Toolbar";
import FeedbackButton from "../../components/form/FeedbackButton";
import BackButton from "../../layout/ActionButton";
import Form from "./Form";
import BackendContent from "../../layout/BackendContent";
import {useParams} from "react-router-dom";
import * as moment from "moment";

export const CreateView = () => {
    const {electionId} = useParams()
    const {mutate: mutateUpdate, isLoading: creating} = mutations.useCreate()

    const backLink = `/election/${electionId}`

    return (
        <BackendContent>

            <Toolbar title={'Neue(n) Kandidat/in erstellen'}>
                <FeedbackButton form="candidateForm" type="submit" pending={creating}>
                    speichern
                </FeedbackButton>

                <BackButton to={backLink}>
                    Zur Liste
                </BackButton>
            </Toolbar>

            <Form
                onSubmit={(formData) => {
                    const data = {
                        ...formData,
                        birthday: moment(formData.birthday).format('YYYY-MM-DD')
                    }
                    mutateUpdate({electionId, data})
                }}
                backLink={backLink}
                pending={creating}
            />
        </BackendContent>
    )
}

export default CreateView