import * as React from "react";
import authService from "./authService";

import {useEffect, useState} from "react";

export const AuthContext = React.createContext({
    payload: null
});
const {Provider} = AuthContext;

//export const AuthConsumer = Consumer;

export const AuthProvider = ({children}) => {
    const [isReady, setReady] = useState(false);

    useEffect(() => {
        if (!authService.isAuthenticated()) {
            if(!authService.loginRedirect()) {
                setReady(true)
            }
        } else {
            setReady(true);
        }
    }, [isReady]);

    return <Provider value={{
        loginRedirect: authService.loginRedirect,
        logout: authService.logout,
        isAuthenticated: authService.isAuthenticated,
        hasRole: authService.hasRole,
        inRoles: authService.inRoles,
        payload: authService.getPayload()
    }}>
        {isReady ? children : null}
    </Provider>;
}