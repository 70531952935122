import React from 'react';
import {FormControlLabel, Switch} from "@material-ui/core";
import {Controller, useFormContext} from "react-hook-form";

const RHFSwitch = ({name, label, defaultValue = false}) => {

    const {control} = useFormContext();

    return <FormControlLabel
        control={
            <Controller
                name={name}
                control={control}
                render={({
                             field: { onChange, onBlur, value, name, ref },
                             fieldState: { invalid, isTouched, isDirty, error },
                             formState
                         }) => (
                    <Switch
                        checked={value === undefined ? defaultValue : value}
                        onChange={(e) => onChange(e.target.checked)}
                        color="primary"
                    />
                )}
            />
        }
        label={label}
    />
}

export default RHFSwitch;

