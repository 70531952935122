import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FooterContent} from "../Footer";

const Wrapper = styled.div`
    max-width: 520px;
    margin: 50px auto 0 auto;
`;

const MiddleContent = ({children}) => {
    return (
        <Wrapper>
            {children}
            <div className={'footer-middle-content'}>
                <FooterContent/>
            </div>
        </Wrapper>
    )
};
MiddleContent.propTypes = {
    children: PropTypes.node.isRequired
};
export default MiddleContent;