import {createMutations, createQueries} from "./entity";

const name = 'district';

export const queries = {
    ...createQueries(name)
}

export const mutations = {
    ...createMutations(name)
}