import BackendContent from "../../layout/BackendContent";
import LoadingOverlay from "../../layout/LoadingOverlay";
import {Toolbar} from "../../layout/toolbar/Toolbar";
import StatisticHeader from "./StatisticHeader";
import React from "react";
import {queries} from "../../api/query/statistics";
import {useParams} from "react-router-dom";
import {Grid, Typography} from "@material-ui/core";
import GenericStatisticTable from "./GenericStatisticTable";
import styled from "styled-components";
import Pie from "./Pie";

const GridItemWrapper = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const OtherView = () => {
    const {electionId} = useParams()
    const {data: ageStatistic, isLoading: ageLoading} = queries.useFetchAge({electionId})
    const {data: genderStatistic, isLoading: genderLoading} = queries.useFetchGender({electionId})
    const {data: deviceStatistic, isLoading: deviceLoading} = queries.useDeviceTypes({electionId})

    const isLoading = ageLoading || genderLoading || deviceLoading

    return (
        <BackendContent>
            <LoadingOverlay open={isLoading}/>
            <Toolbar title={'Statistiken'} />
            <StatisticHeader />

            <Grid container>
                <Grid item xs={12} sm={4} md={6}>
                    <GridItemWrapper>
                        <Typography variant="h4" gutterBottom component="div">Nach Alter</Typography>
                        <Pie statistic={ageStatistic} />
                        <GenericStatisticTable statistic={ageStatistic} />
                    </GridItemWrapper>
                </Grid>

                <Grid item xs={12} sm={4} md={6}>
                    <GridItemWrapper>
                        <Typography variant="h4" gutterBottom component="div">Nach Geschlecht</Typography>
                        <Pie statistic={genderStatistic} />
                        <GenericStatisticTable statistic={genderStatistic} />
                    </GridItemWrapper>
                </Grid>

                <Grid item xs={12} sm={4} md={6}>
                    <GridItemWrapper>
                        <Typography variant="h4" gutterBottom component="div">Nach Gerät</Typography>
                        <Pie statistic={deviceStatistic} />
                        <GenericStatisticTable statistic={deviceStatistic} />
                    </GridItemWrapper>
                </Grid>
            </Grid>



        </BackendContent>
    )
}

export default OtherView