import {useHistory} from "react-router-dom";
import {Typography} from "../../layout/StyledComponent";
import {Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from "@material-ui/core";
import * as moment from "moment";
import React from "react";
import {getStatus} from "../../components/election/period";

export const ElectionTable = ({elections, pagination}) => {
    const history = useHistory();

    if(elections === undefined) {
        return null;
    }

    if(elections.length === 0) {
        return <Typography>Keine Ergebnisse</Typography>
    }

    return (
        <TableContainer className={'sam-table'}>
            <Table size={'small'}>

                <TableHead>
                    <TableRow>
                        <TableCell>Wahl</TableCell>
                        <TableCell>Zeitraum</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Erstellt am</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {elections.map((election) => (
                        <TableRow key={election.id} onClick={() => {
                            history.push(`/election/${election.id}`);
                        }}>
                            <TableCell>
                                {election.name}
                            </TableCell>
                            <TableCell>
                                vom {moment(election.start).format('DD. MMMM YYYY')} 00:00 Uhr<br />
                                bis zum {moment(election.end).format('DD. MMMM YYYY')} 23:59 Uhr
                            </TableCell>
                            <TableCell>
                                {getStatus({state: election.state})}
                            </TableCell>
                            <TableCell>
                                {moment(election.createdAt).format('DD. MMMM YYYY HH:mm')} Uhr
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        {pagination}
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}