import React from 'react'
import BackendContent from "../../layout/BackendContent";
import LoadingOverlay from "../../layout/LoadingOverlay";
import {useParams} from "react-router-dom";
import {mutations, queries} from "../../api/query/school";
import {Toolbar} from "../../layout/toolbar/Toolbar";
import BackButton from "../../layout/ActionButton";
import FeedbackButton from "../../components/form/FeedbackButton";
import Form from "./Form";
import ConfirmationButton from "../../layout/ConfirmationButton";

export const UpdateView = () => {
    const {schoolId, electionId} = useParams()
    const {data: school, isLoading} = queries.useFetch({electionId, id: schoolId})

    const {mutate: mutateUpdate, isLoading: updating} = mutations.useUpdate()
    const {mutate: mutateDelete, isLoading: deleting} = mutations.useDelete()

    const backLink = `/election/${electionId}/school`

    return (
        <BackendContent>
            <LoadingOverlay open={isLoading}/>

            <Toolbar title={'Schule bearbeiten'}>
                <FeedbackButton form="schoolForm" type="submit" pending={updating}>
                    speichern
                </FeedbackButton>

                <ConfirmationButton
                    onSuccess={() => {
                        mutateDelete({electionId: electionId, id: schoolId})
                    }}
                    ButtonProps={{'ml': 2}}
                    dialogTitle={'Schule wirklich löschen?'}
                    dialogText={'Achtung! Falls bereits gewählt wurde und jemand diese Schule ausgewählt hat, kann sie nicht mehr gelöscht werden.'}
                    pending={deleting}
                >
                    löschen
                </ConfirmationButton>


                <BackButton to={backLink}>
                    Zur Liste
                </BackButton>
            </Toolbar>

            {school && <Form
                onSubmit={(formData) => {
                    const data = {...school, ...formData};
                    mutateUpdate({electionId, data})
                }}
                backLink={backLink}
                pending={updating}
                school={school}
            />}
        </BackendContent>
    )
}

export default UpdateView