import React from 'react'
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Image from "../../layout/Image";

export const CandidateTable = ({candidates}) => {

    if(candidates === undefined) {
        return null
    }

    return (
        <TableContainer className={'sam-table'}>
            <Table size={'small'}>

                <TableHead>
                    <TableRow>
                        <TableCell>Platz</TableCell>
                        <TableCell>Bild</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Stimmen</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {candidates.map((candidate, index) => (
                        <TableRow key={candidate.id}>
                            <TableCell>
                                {(index + 1)}.
                            </TableCell>
                            <TableCell>
                                <Image path={candidate.imagePath} height={50} width={50} />
                            </TableCell>
                            <TableCell>
                                {candidate.firstname} {candidate.lastname}
                            </TableCell>
                            <TableCell>
                                Stimmen { candidate.total } ({ candidate.percent }%)
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>

            </Table>
        </TableContainer>
    )
}

export default CandidateTable