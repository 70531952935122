import styled from "styled-components";
import {spacing, style} from '@material-ui/system';
import {
    Card as BaseCard,
    Paper as BasePaper,
    Chip as BaseChip,
    Container as BaseContainer,
    Typography as BaseTypography,
    Avatar as BaseAvatar,
    Button as BaseButton,
    Divider as BaseDivider,
    InputLabel as BaseInputLabel
} from '@material-ui/core';

const rgbColor = style({
    prop: 'rgbcolor',
    cssProperty: 'background-color',
    //transform: value => `${value} !important`,
});

export const Card = styled(BaseCard)`${spacing}`;
export const Container = styled(BaseContainer)`${spacing}`;
export const Paper = styled(BasePaper)`${spacing}`;
export const Chip = styled(BaseChip)`${spacing}${rgbColor}`;
export const Typography = styled(BaseTypography)`${spacing}`;
export const Avatar = styled(BaseAvatar)`${spacing}${rgbColor}`;
export const Button = styled(BaseButton)`${spacing}${rgbColor}`;
export const Divider = styled(BaseDivider)`${spacing}`;
export const InputLabel = styled(BaseInputLabel)`${spacing}`