import {intersection, includes} from "lodash";
import jwtDecode from "jwt-decode";

export const JWT_STORAGE_KEY = 'election.jwt';
export const LOGIN_URL = '/'
export const DEFAULT_LOGIN_SUCCESS_REDIRECT_URL = '/election';

const REDIRECT_URL_KEY = 'uw.redirect-url';


function setItem(key, value) {
    try {
        return localStorage.setItem(key, value);
    } catch (e) {
        return null;
    }
}

function getItem(key) {
    try {
        return localStorage.getItem(key);
    } catch (e) {
        return null;
    }
}

function removeItem(key) {
    try {
        localStorage.removeItem(key);
    } catch (e) {
    }
}

function authService() {
    let payload = null;
    let accessToken = null;

    function getAccessToken() {
        if(accessToken !== null) {
            return accessToken;
        }

        return getItem(JWT_STORAGE_KEY);
    }

    function setAccessToken(token) {
        setItem(JWT_STORAGE_KEY, token);
        accessToken = token;
        payload = jwtDecode(token);
    }

    function getPayload() {
        return payload;
    }

    function isAuthenticated() {
        const accessToken = getItem(JWT_STORAGE_KEY);
        return !!accessToken;
    }

    function logout() {
        removeItem(JWT_STORAGE_KEY);
        loginRedirect();
    }

    function loginRedirect() {
        if(window.location.pathname !== LOGIN_URL) {
            setItem(REDIRECT_URL_KEY, window.location.pathname);
            window.location.href = LOGIN_URL;
            return true
        }

        return false
    }

    function getLoginSuccessRedirectUrl() {
        const redirect = getItem(REDIRECT_URL_KEY);
        removeItem(REDIRECT_URL_KEY);
        return redirect !== null && redirect !== LOGIN_URL ? redirect : DEFAULT_LOGIN_SUCCESS_REDIRECT_URL;
    }

    function hasRole(role) {
        return includes(payload.roles, role);
    }

    function inRoles(roles) {
        if(payload === null) {
            return false;
        }

        return intersection(payload.roles, roles).length !== 0;
    }

    function reset() {
        accessToken = null;
        payload = null;
        removeItem(JWT_STORAGE_KEY);
    }

    function isExpired() {
        if(payload === null) {
            return true
        }

        payload = jwtDecode(accessToken);

        if (Date.now() > payload.exp * 1000) {
            return true;
        }

        return false;
    }


    return {

        init: function() {
            accessToken = this.getAccessToken();
            if(accessToken !== null) {
                payload = jwtDecode(accessToken);

                if (Date.now() >= payload.exp * 1000) {
                    reset();
                }
            }
        },

        getAccessToken,
        setAccessToken,
        getPayload,
        isAuthenticated,
        logout,
        loginRedirect,
        getLoginSuccessRedirectUrl,
        hasRole,
        inRoles,
        isExpired,
        reset
    }
}

const instance = new authService();
export default instance;