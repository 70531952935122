
export function getDeviceType(type) {
    if(type === 1) {
        return 'Handy'
    }

    if(type === 2) {
        return 'Tablet'
    }

    return 'PC'
}