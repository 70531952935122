import React, {useContext} from 'react';
import {AppBar as BaseAppBar, IconButton, Toolbar} from '@material-ui/core';
import {MeetingRoom as MeetingRoomIcon} from '@material-ui/icons'
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

import {AuthContext} from "../../components/auth/AuthProvider";
import {Title} from "./Title";
import DehazeIcon from '@material-ui/icons/Dehaze';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({

    menuButton: {
        marginRight: theme.spacing(2),
        marginLeft: -10
    },
    appBar: {
        color: 'rgb(100, 100, 100)',
        boxShadow: 'rgba(53, 64, 82, 0.05) 0px 0px 14px 0px',
        background: 'rgb(255, 255, 255)',

        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        borderBottom: `1px solid ${theme.palette.success.main}`
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
}));

const AppBar = ({open = false, handleClick, children}) => {
    const classes = useStyles();
    const {logout} = useContext(AuthContext);

    return (
        <BaseAppBar
            position="sticky"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}
        >
            <Toolbar className={classes.toolbar}>
                <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="open drawer"
                >
                    <DehazeIcon/>
                </IconButton>
                <Title/>

                <div className={classes.grow}/>

                <IconButton
                    edge="end"
                    onClick={() => logout()}
                    color="inherit"
                >
                    <MeetingRoomIcon/>
                </IconButton>
            </Toolbar>
        </BaseAppBar>
    )

}

export default AppBar;