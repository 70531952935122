import React from 'react'
import BackendContent from "../../layout/BackendContent";
import {Toolbar} from "../../layout/toolbar/Toolbar";
import {useParams} from "react-router-dom";
import Form from "./Form";
import {mutations} from "../../api/query/tanCode";
import {Card, CardContent, CardHeader, Chip, Grid} from "@material-ui/core";
import moment from "moment";

function getResult(tanCodeResult) {

    if(tanCodeResult === null) {
        return (
            <span>Dieser TanCode konnte nicht gefunden werden</span>
        )
    }

    const {code, isUsed, usedAt} = tanCodeResult

    return (
        <div>
            <span>TanCode:</span> {code}<br />
            <span>benutzt:</span> {isUsed ? <Chip label="Ja" color="primary" variant="outlined" /> : <Chip label="Nein" color="primary" variant="outlined" /> }<br />
            {isUsed && <span>am {moment(usedAt).format('LLLL')} Uhr</span>}
        </div>
    )
}

export const SearchView = () => {

    const {electionId} = useParams()
    const {data: tanCodeResult, mutate: mutateSearch, isLoading, isSuccess} = mutations.useSearch()

    const handleSubmit = (data) => {
        mutateSearch({
            electionId,
            tanCode: data.tanCode
        })
    }

    return (
        <BackendContent>
            <Toolbar title={'Tansuche'} />

            <Grid container spacing={6}>
                <Grid item xs={12} sm={6}>
                    <Form pending={isLoading} onSubmit={handleSubmit} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardHeader title={'Suchergebnis'} />
                        <CardContent>
                            {isSuccess && getResult(tanCodeResult)}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </BackendContent>
    )
}

export default SearchView