import {
    FaFileAudio,
    FaFileArchive,
    FaFileVideo,
    FaFile,
    FaFileImage,
    FaFileAlt,
    FaFileCsv,
    FaFileWord,
    FaFileCode,
    FaFilePowerpoint,
    FaFileExcel,
    FaFont,
    FaFilePdf
} from 'react-icons/fa';

export const mimeTypes = {
    'video/3gpp2'                                                               : FaFileVideo, // '3g2'
    'video/3gp'                                                                 : FaFileVideo, //  '3gp'
    'video/3gpp'                                                                : FaFileVideo, //  '3gp'
    'application/x-compressed'                                                  : FaFileArchive, // '7zip',
    'audio/x-acc'                                                               : FaFileAudio, //  'aac',
    'audio/ac3'                                                                 : FaFileAudio, //'ac3',
    'application/postscript'                                                    : FaFile, // 'ai',
    'audio/x-aiff'                                                              : FaFileAudio, //'aif',
    'audio/aiff'                                                                : FaFileAudio, //'aif',
    'audio/x-au'                                                                : FaFileAudio, //'au',
    'video/x-msvideo'                                                           : FaFileVideo, // 'avi',
    'video/msvideo'                                                             : FaFileVideo, // 'avi',
    'video/avi'                                                                 : FaFileVideo, // 'avi',
    'application/x-troff-msvideo'                                               : FaFileVideo, // 'avi',
    'application/macbinary'                                                     : FaFile, // 'bin',
    'application/mac-binary'                                                    : FaFile, // 'bin',
    'application/x-binary'                                                      : FaFile, // 'bin',
    'application/x-macbinary'                                                   : FaFile, // 'bin',
    'image/bmp'                                                                 : FaFileImage, // 'bmp',
    'image/x-bmp'                                                               : FaFileImage, // 'bmp',
    'image/x-bitmap'                                                            : FaFileImage, // 'bmp',
    'image/x-xbitmap'                                                           : FaFileImage, // 'bmp',
    'image/x-win-bitmap'                                                        : FaFileImage, // 'bmp',
    'image/x-windows-bmp'                                                       : FaFileImage, // 'bmp',
    'image/ms-bmp'                                                              : FaFileImage, // 'bmp',
    'image/x-ms-bmp'                                                            : FaFileImage, // 'bmp',
    'application/bmp'                                                           : FaFileImage, // 'bmp',
    'application/x-bmp'                                                         : FaFileImage, // 'bmp',
    'application/x-win-bitmap'                                                  : FaFileImage, // 'bmp',
    'application/cdr'                                                           : FaFile, // 'cdr',
    'application/coreldraw'                                                     : FaFile, // 'cdr',
    'application/x-cdr'                                                         : FaFile, // 'cdr',
    'application/x-coreldraw'                                                   : FaFile, // 'cdr',
    'image/cdr'                                                                 : FaFile, // 'cdr',
    'image/x-cdr'                                                               : FaFile, // 'cdr',
    'zz-application/zz-winassoc-cdr'                                            : FaFile, // 'cdr',
    'application/mac-compactpro'                                                : FaFile, // 'cpt',
    'application/pkix-crl'                                                      : FaFile, // 'crl',
    'application/pkcs-crl'                                                      : FaFile, // 'crl',
    'application/x-x509-ca-cert'                                                : FaFile, // 'crt',
    'application/pkix-cert'                                                     : FaFile, // 'crt',
    'text/css'                                                                  : FaFileAlt, // 'css',
    'text/x-comma-separated-values'                                             : FaFileCsv, // 'csv',
    'text/comma-separated-values'                                               : FaFileCsv, // 'csv',
    'application/vnd.msexcel'                                                   : FaFileCsv, // 'csv',
    'application/x-director'                                                    : FaFile, // 'dcr',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'   : FaFileWord, //'docx'
    'application/x-dvi'                                                         : FaFile, // 'dvi',
    'message/rfc822'                                                            : FaFile, // 'eml',
    'application/x-msdownload'                                                  : FaFile, // 'exe',
    'video/x-f4v'                                                               : FaFileVideo, // 'f4v',
    'audio/x-flac'                                                              : FaFileAudio, // 'flac',
    'video/x-flv'                                                               : FaFileVideo, // 'flv',
    'image/gif'                                                                 : FaFileImage, // 'gif',
    'application/gpg-keys'                                                      : FaFile, // 'gpg',
    'application/x-gtar'                                                        : FaFileArchive, // 'gtar',
    'application/x-gzip'                                                        : FaFileArchive, // 'gzip',
    'application/mac-binhex40'                                                  : FaFile, // 'hqx',
    'application/mac-binhex'                                                    : FaFile, // 'hqx',
    'application/x-binhex40'                                                    : FaFile, // 'hqx',
    'application/x-mac-binhex40'                                                : FaFile, // 'hqx',
    'text/html'                                                                 : FaFileAlt, // 'html',
    'image/x-icon'                                                              : FaFileImage, // 'ico',
    'image/x-ico'                                                               : FaFileImage, // 'ico',
    'image/vnd.microsoft.icon'                                                  : FaFileImage, // 'ico',
    'text/calendar'                                                             : FaFile, // 'ics',
    'application/java-archive'                                                  : FaFileArchive, // 'jar',
    'application/x-java-application'                                            : FaFileArchive, // 'jar',
    'application/x-jar'                                                         : FaFileArchive, // 'jar',
    'image/jp2'                                                                 : FaFileImage, // 'jp2',
    'video/mj2'                                                                 : FaFileVideo, //'jp2',
    'image/jpx'                                                                 : FaFileImage, // 'jp2',
    'image/jpm'                                                                 : FaFileImage, // 'jp2',
    'image/jpeg'                                                                : FaFileImage, // 'jpeg',
    'image/pjpeg'                                                               : FaFileImage, // 'jpeg',
    'application/x-javascript'                                                  : FaFileCode, // 'js',
    'application/json'                                                          : FaFileCode, // 'json',
    'text/json'                                                                 : FaFileCode, // 'json',
    'application/vnd.google-earth.kml+xml'                                      : FaFile, // 'kml',
    'application/vnd.google-earth.kmz'                                          : FaFile, // 'kmz',
    'text/x-log'                                                                : FaFileAlt, // 'log',
    'audio/x-m4a'                                                               : FaFileAudio, // 'm4a',
    'audio/mp4'                                                                 : FaFileAudio, // 'm4a',
    'application/vnd.mpegurl'                                                   : FaFile, // 'm4u',
    'audio/midi'                                                                : FaFileAudio, // 'mid',
    'application/vnd.mif'                                                       : FaFile, // 'mif',
    'video/quicktime'                                                           : FaFileVideo, // 'mov',
    'video/x-sgi-movie'                                                         : FaFileVideo, // 'movie',
    'audio/mpeg'                                                                : FaFileAudio, // 'mp3',
    'audio/mpg'                                                                 : FaFileAudio, // 'mp3',
    'audio/mpeg3'                                                               : FaFileAudio, // 'mp3',
    'audio/mp3'                                                                 : FaFileAudio, // 'mp3',
    'video/mp4'                                                                 : FaFileVideo, // 'mp4',
    'video/mpeg'                                                                : FaFileVideo, // 'mpeg',
    'application/oda'                                                           : FaFile, // 'oda',
    'audio/ogg'                                                                 : FaFileAudio, // 'ogg',
    'video/ogg'                                                                 : FaFileVideo, // 'ogg',
    'application/ogg'                                                           : FaFileImage, // 'ogg',
    'font/otf'                                                                  : FaFile, // 'otf',
    'application/x-pkcs10'                                                      : FaFile, // 'p10',
    'application/pkcs10'                                                        : FaFile, // 'p10',
    'application/x-pkcs12'                                                      : FaFile, // 'p12',
    'application/x-pkcs7-signature'                                             : FaFile, // 'p7a',
    'application/pkcs7-mime'                                                    : FaFile, // 'p7c',
    'application/x-pkcs7-mime'                                                  : FaFile, // 'p7c',
    'application/x-pkcs7-certreqresp'                                           : FaFile, // 'p7r',
    'application/pkcs7-signature'                                               : FaFile, // 'p7s',
    'application/pdf'                                                           : FaFilePdf, // 'pdf',
    'application/octet-stream'                                                  : FaFilePdf, // 'pdf',
    'application/x-x509-user-cert'                                              : FaFile, // 'pem',
    'application/x-pem-file'                                                    : FaFile, // 'pem',
    'application/pgp'                                                           : FaFile, // 'pgp',
    'application/x-httpd-php'                                                   : FaFileCode, // 'php',
    'application/php'                                                           : FaFileCode, // 'php',
    'application/x-php'                                                         : FaFileCode, // 'php',
    'text/php'                                                                  : FaFileCode, // 'php',
    'text/x-php'                                                                : FaFileCode, // 'php',
    'application/x-httpd-php-source'                                            : FaFileCode, // 'php',
    'image/png'                                                                 : FaFileImage, // 'png',
    'image/x-png'                                                               : FaFileImage, // 'png',
    'application/powerpoint'                                                    : FaFilePowerpoint, //'ppt'
    'application/vnd.ms-powerpoint'                                             : FaFilePowerpoint, // 'ppt'
    'application/vnd.ms-office'                                                 : FaFilePowerpoint, // 'ppt',
    'application/msword'                                                        : FaFilePowerpoint, // 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation' : FaFilePowerpoint, //'pptx'
    'application/x-photoshop'                                                   : FaFile, // 'psd',
    'image/vnd.adobe.photoshop'                                                 : FaFile, // 'psd',
    'audio/x-realaudio'                                                         : FaFile, // 'ra',
    'audio/x-pn-realaudio'                                                      : FaFile, // 'ram',
    'application/x-rar'                                                         : FaFileArchive, // 'rar',
    'application/rar'                                                           : FaFileArchive, // 'rar',
    'application/x-rar-compressed'                                              : FaFileArchive, // 'rar',
    'audio/x-pn-realaudio-plugin'                                               : FaFile, // 'rpm',
    'application/x-pkcs7'                                                       : FaFile, // 'rsa',
    'text/rtf'                                                                  : FaFileAlt, // 'rtf',
    'text/richtext'                                                             : FaFileAlt, //  'rtx',
    'video/vnd.rn-realvideo'                                                    : FaFileVideo, //  'rv',
    'application/x-stuffit'                                                     : FaFile, // 'sit',
    'application/smil'                                                          : FaFile, // 'smil',
    'text/srt'                                                                  : FaFile, // 'srt',
    'image/svg+xml'                                                             : FaFileImage, // 'svg',
    'application/x-shockwave-flash'                                             : FaFile, // 'swf',
    'application/x-tar'                                                         : FaFileArchive, // 'tar',
    'application/x-gzip-compressed'                                             : FaFileArchive, // 'tgz',
    'image/tiff'                                                                : FaFileImage, // 'tiff',
    'font/ttf'                                                                  : FaFont, // 'ttf',
    'text/plain'                                                                : FaFileAlt, // 'txt',
    'text/x-vcard'                                                              : FaFile, // 'vcf',
    'application/videolan'                                                      : FaFileVideo, // 'vlc',
    'text/vtt'                                                                  : FaFileAlt, // 'vtt',
    'audio/x-wav'                                                               : FaFileAudio, // 'wav',
    'audio/wave'                                                                : FaFileAudio, // 'wav',
    'audio/wav'                                                                 : FaFileAudio, // 'wav',
    'application/wbxml'                                                         : FaFile, // 'wbxml',
    'video/webm'                                                                : FaFileVideo, // 'webm',
    'image/webp'                                                                : FaFile, // 'webp',
    'audio/x-ms-wma'                                                            : FaFileAudio, // 'wma',
    'application/wmlc'                                                          : FaFile, // 'wmlc',
    'video/x-ms-wmv'                                                            : FaFileVideo, // 'wmv',
    'video/x-ms-asf'                                                            : FaFileVideo, // 'wmv',
    'font/woff'                                                                 : FaFont, // 'woff',
    'font/woff2'                                                                : FaFont, // 'woff2',
    'application/xhtml+xml'                                                     : FaFileAlt, // 'xhtml',
    'application/excel'                                                         : FaFileExcel, // 'xl',
    'application/msexcel'                                                       : FaFileExcel, // 'xls',
    'application/x-msexcel'                                                     : FaFileExcel, // 'xls',
    'application/x-ms-excel'                                                    : FaFileExcel, // 'xls',
    'application/x-excel'                                                       : FaFileExcel, // 'xls',
    'application/x-dos_ms_excel'                                                : FaFileExcel, // 'xls',
    'application/xls'                                                           : FaFileExcel, // 'xls',
    'application/x-xls'                                                         : FaFileExcel, // 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'         : FaFileExcel, // 'xlsx',
    'application/vnd.ms-excel'                                                  : FaFileExcel, // 'xlsx',
    'application/xml'                                                           : FaFileAlt, // 'xml',
    'text/xml'                                                                  : FaFileAlt, // 'xml',
    'text/xsl'                                                                  : FaFileAlt, // 'xsl',
    'application/xspf+xml'                                                      : FaFileAlt, // 'xspf',
    'application/x-compress'                                                    : FaFileArchive, // 'z',
    'application/x-zip'                                                         : FaFileArchive, // 'zip',
    'application/zip'                                                           : FaFileArchive, // 'zip',
    'application/x-zip-compressed'                                              : FaFileArchive, // 'zip',
    'application/s-compressed'                                                  : FaFileArchive, // 'zip',
    'multipart/x-zip'                                                           : FaFileArchive, // 'zip',
    'text/x-scriptzsh'                                                          : FaFileAlt, // 'zsh'
};

const mimeTypesImages = [
    'image/png', 'image/x-png', 'image/gif', 'image/jpg', 'image/jpeg', 'image/svg+xml'
];

export function getFileIcon(mimeType) {
    if(mimeTypes[mimeType]) {
        return mimeTypes[mimeType];
    }
    return FaFile;
}

export function isImage(mimeType) {
    return mimeTypesImages.includes(mimeType);
}