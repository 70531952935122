import React from 'react'
import {Toolbar as BaseToolbar} from "@material-ui/core";
import styled from "styled-components";
import {Typography} from "../StyledComponent";

const StyledToolbar = styled(BaseToolbar)`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    @media (min-width: 600px) {
        min-height: 48px;
    }
`

const Headline = styled(Typography)`
    margin-right: auto;
    margin-top: 0;
`

export const Toolbar = ({children, title, ...rest}) => {
    return (
        <>
            <StyledToolbar {...rest}  disableGutters={true}>
            {title && <Headline mt={2} variant={'h3'}>
                {title}
            </Headline>
            }
            {children}
            </StyledToolbar>
        </>
    )
}