import IconDashboard from "@material-ui/icons/Dashboard";


export const sidebarMenuItems = [
    {
        name: 'Wahlen',
        link: '/election',
        Icon: IconDashboard,
        divider: true
    },
    {
        header: 'Wahlen Stammdaten',
        name: 'Stadtteile',
        dependsOnParameters: ['electionId'],
        link: '/election/:electionId/district',
        Icon: IconDashboard
    },
    {
        name: 'Textbereiche',
        dependsOnParameters: ['electionId'],
        link: '/election/:electionId/snippet',
        Icon: IconDashboard,
        divider: true
    },
    {
        header: 'Wahl und Statistiken',
        name: 'Schulen',
        dependsOnParameters: ['electionId'],
        link: '/election/:electionId/school',
        Icon: IconDashboard
    },
    {
        name: 'Tansuche',
        dependsOnParameters: ['electionId'],
        link: '/election/:electionId/tanCode/search',
        Icon: IconDashboard
    },
    {
        name: 'Wahl',
        dependsOnParameters: ['electionId'],
        link: '/election/:electionId/voting',
        Icon: IconDashboard
    },
    {
        name: 'Statistiken',
        dependsOnParameters: ['electionId'],
        link: '/election/:electionId/statistics',
        Icon: IconDashboard
    },
];