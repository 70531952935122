import React from 'react'
import InputLabel from "@material-ui/core/InputLabel";
import {Controller, useFormContext} from "react-hook-form";
import Select from "@material-ui/core/Select";
import {MenuItem} from "@material-ui/core";
import RHFErrorMessage from "../../../components/form/RHFErrorMessage";
import FormControl from "@material-ui/core/FormControl";

const items = [
    {value: 'f', label: 'weiblich'},
    {value: 'm', label: 'männlich'},
    {value: 'd', label: 'divers'}
]

export const RHFGenderField = ({name, label, ...rest}) => {
    const {control} = useFormContext();

    const labelId = `${name}-label`;

    return (
        <FormControl fullWidth {...rest}>
            <Controller
                name={name}
                control={control}
                defaultValue={''}
                render={({
                             field: {onChange, onBlur, value, name, ref},
                             fieldState: {invalid, isTouched, isDirty, error},
                             formState
                         }) => {

                    return (
                        <>
                            <InputLabel id={labelId} error={error}>{label}</InputLabel>
                            <Select
                                id={name}
                                labelId={labelId}
                                label={label}
                                error={error}
                                variant="outlined"
                                value={value}
                                defaultValue={value}
                                onChange={onChange}
                            >
                                <MenuItem value=""><em>Bitte auswählen</em></MenuItem>
                                {items.map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            <RHFErrorMessage errors={error} name={name}/>
                        </>
                    )


                }}
            />
        </FormControl>
    )
}

export default RHFGenderField