import React from 'react'
import {CardContent, Divider, Grid} from "@material-ui/core";
import {Card} from "../../layout/StyledComponent";
import CardHeader from "@material-ui/core/CardHeader";
import moment from 'moment'

export const Election = ({election}) => {

    if(election === undefined) {
        return null
    }

    return (
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Card style={{height: '100%'}}>
                    <CardHeader
                        title={election.name}
                    />

                    <CardContent>
                        Online von: {moment(election.start).format('DD MMMM YYYY 00:00')} Uhr<br />
                        Online bis: {moment(election.end).format('DD MMMM YYYY 23:59')} Uhr<br />
                        <Divider />
                        {election.description && <p dangerouslySetInnerHTML={ { __html: election.description}} />}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default Election