import {useMutation, useQueryClient} from "react-query";
import axios from "../index";
import {toast} from "react-hot-toast";
import {createUseList} from "./entity/list";
import {createUseFetch} from "./entity/fetch";
import {createUseFileUploader} from "./entity/upload";
import {useHistory} from "react-router-dom";
import {createUseUpdate} from "./entity/update";
import queryClient from "../queryClient";
import {createUseCreate} from "./entity/create";

const name = 'candidate';

export const queries = {
    useList: createUseList(name),
    useFetch: createUseFetch(name)
}

const useRemoveImage = () => {
    const queryClient = useQueryClient()
    let tmpElectionId = null
    return useMutation(async ({electionId, id}) => {

        tmpElectionId = electionId
        try {
            return await axios.delete(`/election/${electionId}/${name}/${id}/image`).then((response) => {
                return response.data
            });
        } catch (err) {
            throw err;
        }
    }, {
        onSuccess: (data, variables, context) => {
            toast.success("Das Bild wurde erfolgreich gelöscht")
            queryClient.setQueryData([name, tmpElectionId, data.id], data)
        },
        onError: (error, variables, context) => {
            toast.error("Das Bild konnte nicht gelöscht werden")
        }
    })
}

const createUseDelete = (name) => () => {
    const history = useHistory()

    let tmpElectionId = null;

    return useMutation(async ({electionId, id}) => {
        tmpElectionId = electionId
        try {
            return await axios.delete(`/election/${electionId}/${name}/${id}`).then((response) => {
                return response.data
            });
        } catch (err) {
            throw err;
        }
    }, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries([name, tmpElectionId, data.id], data)
            toast.success("Der Datensatz wurde erfolgreich gelöscht")
            history.push(`/election/${tmpElectionId}/candidate`)
        },
        onError: (error, variables, context) => {
            toast.error("Der Datensatz konnte nicht gelöscht werden")
        }
    })
}

export const mutations = {
    useCreate: createUseCreate(name),
    useDelete: createUseDelete(name),
    useUpdate: createUseUpdate(name),
    useFileUploader: createUseFileUploader(name, `/${name}/:id/file`, ({data, queryClient, cacheName, uploadCacheKey}) => {
        const {response} = data[data.length - 1]
        queryClient.setQueryData([cacheName, response.id], response)
        queryClient.setQueryData(uploadCacheKey, {})
    }),
    useRemoveImage
}