import axios from '../../index'
import {useQuery, useQueryClient} from "react-query";
import {usePagination} from "./pagination";
import {isEmpty} from "lodash";

export const createUseList = (name) => ({electionId, params}) => {
    const queryClient = useQueryClient()
    const pagination = usePagination([name, electionId, 'pagination'])

    let queryKey = [name, electionId]
    if(params !== undefined && !isEmpty(params)) {
        queryKey.push(params)
    }

    return {
        pagination: {...pagination}, ...useQuery(queryKey, async () => {
            try {
                return await axios.get(`/election/${electionId}/${name}`, {params: params}).then((response) => {
                    queryClient.setQueryData([name, electionId, 'pagination'], response.data.pagination)
                    return response.data.results
                });
            } catch (err) {
                throw err;
            }
        })
    }
}