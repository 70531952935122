import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import Dropzone from "react-dropzone";
import {Paper} from "@material-ui/core";
import {CloudUpload} from "@material-ui/icons";

const useStyles = makeStyles({
    root: {
        backgroundColor: '#eee',
        textAlign: 'center',
        cursor: 'pointer',
        padding: '10px',
        marginTop: '20px'
    },
    icon: {
        marginTop: '16px',
        color: '#888',
        fontSize: '42px'
    }
})

export const FileInput = ({name, onChange, ...rest}) => {
    const styles = useStyles()

    let {droptext} = rest
    if(droptext === undefined) {
        droptext = 'Drag und Drop für Dateien oder klicken, um Dateien hochzuladen'
    }

    return (
        <Dropzone onDrop={onChange} {...rest}>
            {({getRootProps, getInputProps}) => (
                <Paper className={styles.root} variant="outlined" {...getRootProps()}>
                    <CloudUpload className={styles.icon} />
                    <input {...getInputProps()} name={name} />
                    <p>
                        {droptext}
                    </p>
                </Paper>
            )}
        </Dropzone>
    )
}