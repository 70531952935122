const PAST = 'past'
const FUTURE = 'future'
const OPEN = 'open'

export const getStatus = ({state}) => {

    switch(state) {
        case PAST:
            return 'geschlossen (Onlinewahl bereits vorbei)'
        case OPEN:
            return 'offen (Onlinewahl läuft)'
        case FUTURE:
            return 'geschlossen (Onlinewahl öffnet bald)'
        default:
            return 'unbekannter Status'
    }
}