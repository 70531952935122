import React from "react";
import AppBar from "./header/AppBar";



// const AppBar = styled(BaseAppBar)`
//     color: rgb(158, 158, 158);
//     box-shadow: rgba(53, 64, 82, 0.05) 0px 0px 14px 0px;
//     background: rgb(255, 255, 255);
// `;
//


const Header = () => {

    return (
        <AppBar />
    )
};

export default Header;