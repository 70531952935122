import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {Box} from "@material-ui/core";
import {Typography} from "./StyledComponent";
import Breadcrumbs from "./Breadcrumbs";

const Wrapper = styled.div`
    padding: 10px 30px;
`;

const BackendContent = ({headline, breadcrumb, pending = false, topMenu, children}) => {

    const content = (
        <>
            {
                breadcrumb &&
                <Box mt={2} mb={4}>
                    {!pending && <Breadcrumbs items={breadcrumb}/>}
                </Box>
            }
            {/*{breadcrumb && <Divider mb={4} mt={4}/>}*/}
            {
                headline &&
                <Typography mt={2} variant={'h3'}>
                    {headline}
                </Typography>
            }

            {children}
        </>
    )

    if (topMenu) {
        return (
            <>
                {topMenu}
                <Wrapper>
                    {content}
                </Wrapper>
            </>
        )
    }

    return (
        <Wrapper>
            {content}
        </Wrapper>
    )
};
BackendContent.propTypes = {
    headline: PropTypes.string,
    breadcrumb: PropTypes.array
};
export default BackendContent;