import React from "react";
import {Typography} from "../../layout/StyledComponent";
import {Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from "@material-ui/core";
import * as moment from "moment";
import {getDeviceType} from "./deviceType";

export const VotingTable = ({votings, pagination}) => {

    if(votings === undefined) {
        return null;
    }

    if(votings.length === 0) {
        return <Typography>Keine Ergebnisse</Typography>
    }

    return (
        <TableContainer className={'sam-table'}>
            <Table size={'small'}>

                <TableHead>
                    <TableRow>
                        <TableCell>TAN Code (erste 2 Stellen)</TableCell>
                        <TableCell>Kandidat</TableCell>
                        <TableCell>Gewählt über</TableCell>
                        <TableCell>Erstellt am</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {votings.map((voting) => (
                        <TableRow key={voting.id} >
                            <TableCell>
                                {voting.tan.cappedTan}
                            </TableCell>
                            <TableCell>
                                {voting.candidate.firstname} {voting.candidate.lastname}
                            </TableCell>
                            <TableCell>
                                {getDeviceType(voting.deviceType)}
                            </TableCell>
                            <TableCell>
                                {moment(voting.createdAt).format('DD. MMMM YYYY HH:mm')} Uhr
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        {pagination}
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}