import React, {useState} from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import MenuItem from "./MenuItem";
import PropTypes from "prop-types";

const ExpandableMenuItem = ({item}) => {
    const [open, setOpen] = useState(false);
    const Icon = item.Icon || null;

    function handleClick() {
        setOpen(!open)
    }

    return (
        <>
            <ListItem button onClick={handleClick}>

                {Icon && (
                    <ListItemIcon>
                        <Icon/>
                    </ListItemIcon>
                )}

                <ListItemText primary={item.name} inset={!Icon}/>

                {!open && <IconExpandMore/>}
                {open && <IconExpandLess/>}
            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <Divider/>
                <List component="div" disablePadding>
                    {item.items.map((item, index) => (
                        <MenuItem item={item} key={index}/>
                    ))}
                </List>
            </Collapse>
        </>
    )
};
ExpandableMenuItem.propTypes = {
    item: PropTypes.object.isRequired
};
export default ExpandableMenuItem;