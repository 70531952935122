import ObjectID from "bson-objectid";

export const UPLOAD_STATE = {
    INIT: 'init',
    IN_PROGRESS: 'inProgress',
    COMPLETE: 'complete',
    FAILED: 'failed'
};

export default function createUploadFile(file) {
    return {
        id: ObjectID(),
        file: file,
        status: UPLOAD_STATE.INIT,
        progress: 0,
        secondsRemaining: 0,
        bytesPerSecond: 0,
        createdAt: new Date(),
        startedAt: null,
        response: null,
        error: null
    }
};