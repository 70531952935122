import React, {useState} from 'react'
import FeedbackButton from "../components/form/FeedbackButton";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {Button} from "./StyledComponent";

export const ConfirmationButton = ({dialogTitle, dialogText, children, onSuccess, onCancel, ButtonProps={}, pending= false}) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if(onCancel) {
            onCancel()
        }
        setOpen(false);
    };

    const handleSuccess = () => {
        if(onSuccess) {
            onSuccess()
        }
        setOpen(false);
    }

    return (
        <>
            <Button
                variant={'contained'}
                color={"primary"}
                fullWidth={false}
                onClick={handleClickOpen}
                {...ButtonProps}
            >
                {children}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Abbruch
                    </Button>
                    <FeedbackButton onClick={handleSuccess} color="primary" pending={pending} autoFocus>
                        OK
                    </FeedbackButton>
                </DialogActions>
            </Dialog>
        </>

    )
}

export default ConfirmationButton