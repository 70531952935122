import React from 'react'
import {Link, useParams} from "react-router-dom";
import {Button} from "../../layout/StyledComponent";
import styled from "styled-components";

const HeaderWrapper = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const StatisticHeader = () => {
    const {electionId} = useParams()

    return (
        <HeaderWrapper>

            <Button
                component={Link}
                to={`/election/${electionId}/statistics`}
                variant="contained"
                color="primary"
                ml={2}
            >
                Generell
            </Button>

            <Button
                component={Link}
                to={`/election/${electionId}/statistics/voting`}
                variant="contained"
                color="primary"
                ml={2}
            >
                Stimmabgabe Zeitraum
            </Button>

            <Button
                component={Link}
                to={`/election/${electionId}/statistics/school`}
                variant="contained"
                color="primary"
                ml={2}
            >
                Schulen
            </Button>

            <Button
                component={Link}
                to={`/election/${electionId}/statistics/other`}
                variant="contained"
                color="primary"
                ml={2}
            >
                Andere
            </Button>

        </HeaderWrapper>
    )
}

export default StatisticHeader