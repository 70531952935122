import React from "react";
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import Menu from "./Menu";
import ListItem from "@material-ui/core/ListItem";
import {sidebarMenuItems} from '../../pages/menuLinks';


const Wrapper = styled.div`
@media (min-width: 960px) {
    width: 270px;
    flex-shrink: 0;
}
`;

const InnerWrapper = styled.div`
@media (max-width:959px) {
    display: none;
}
`;

const Logo = styled(ListItem)`
    font-size: 1.125rem;
    font-weight: 600;
    color: rgb(255, 255, 255);
    background-color: rgb(153, 204, 51);
    padding-left: 24px;
    padding-right: 24px;

    @media (min-width: 600px) {
        min-height: 64px;
    }
`;

const ScrollbarContainer = styled.div`
    position: relative;
    height: 100%;
    overflow: hidden;
    touch-action: auto;
`;

const paperProps = {
    elevation: 0,
    // paperAnchorDockedLeft: true,
    style: {
        width: '270px'
    }
};
//background-color: rgb(27, 36, 48);

const SidebarFooter = styled.div`
    background-color: rgb(153, 204, 51);
    padding: 11px 16px;
    min-height: 64px;
`;

const Sidebar = () => {

    return (
        <Wrapper>
            <InnerWrapper>
                <Drawer variant="permanent" anchor={'left'} PaperProps={paperProps}>
                    <Logo>

                    </Logo>

                    <ScrollbarContainer>
                        <Menu menuItems={sidebarMenuItems}/>
                    </ScrollbarContainer>

                    <SidebarFooter />
                </Drawer>
            </InnerWrapper>
        </Wrapper>
    )
};
export default Sidebar;