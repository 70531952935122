import React from 'react'
import {
    Card,
    CardContent,
    CardHeader,
    Grid
} from "@material-ui/core";
import CandidateTable from "./CandidateTable";
import {Chip} from "../../layout/StyledComponent";

export const GeneralStatistic = ({statistic}) => {

    if(statistic === undefined) {
        return null
    }

    const {table: candidates, total} = statistic

    return (
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title={'Kandidaten'} />
                    <CardContent>
                        <p>
                            <span>Wähler gesamt:</span>
                            <Chip label={total} />
                        </p>

                        <CandidateTable candidates={candidates} />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default GeneralStatistic