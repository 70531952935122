import './theme/scss/main.scss';

import {BrowserRouter} from 'react-router-dom';
import theme, {themeOptions} from './theme';
import {MuiThemeProvider, CssBaseline, StylesProvider} from "@material-ui/core";
import {ThemeProvider as StyledThemeProvider} from 'styled-components';
import {IconContext} from "react-icons";
import {QueryClientProvider} from "react-query";
import queryClient from "./api/queryClient";
import {Toaster} from "react-hot-toast";
import {ReactQueryDevtools} from 'react-query/devtools'
import Routing from "./pages/Routing";

import moment from 'moment'
import 'moment/locale/de'
moment.locale('de')

function App() {
  return (
      <MuiThemeProvider theme={theme}>
        <StyledThemeProvider theme={themeOptions}>
          <StylesProvider injectFirst>
              <CssBaseline/>
              <BrowserRouter>
                      <IconContext.Provider value={{color: 'rgba(0, 0, 0, 0.54)'}}>
                          <Toaster position={"top-right"}/>
                          <QueryClientProvider client={queryClient}>
                              <Routing/>
                              <ReactQueryDevtools initialIsOpen={false} />
                          </QueryClientProvider>
                      </IconContext.Provider>
              </BrowserRouter>
          </StylesProvider>
        </StyledThemeProvider>
      </MuiThemeProvider>
  );
}

export default App;
