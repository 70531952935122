import React from 'react'
import {Avatar, IconButton} from "@material-ui/core";
import {IconContext} from "react-icons"
import { Check } from '@material-ui/icons';
import {makeStyles} from "@material-ui/core/styles";
import {LinearProgressBar} from "./ProgressBar";
import {UPLOAD_STATE} from "./factory";
import DeleteIcon from "@material-ui/icons/Delete";
import {getFileIcon} from "../file/mimeTypes";
import {sizeInWords} from "../file/size";


const useStyles = makeStyles((theme) => ({
    root: {
        cursor: "pointer",
        '&:hover': {
            backgroundColor: '#eeeeee'
        }
    },
    blue: {
        backgroundColor: theme.palette.primary.main
    },
    icon: {
        color: '#fff'
    },
    fileList: {
        listStyleType: 'none',
        margin: '20px 0 0 0',
        padding: 0
    },
    fileListItem: {
        listStyleType: 'none'
    },
    fileInfo: {
        display: 'flex',
        alignItems: 'center',
        ' :last': {
            marginLeft: '10px'
        }
    },
    fileName: {
        marginLeft: '10px',
        wordBreak: 'break-word'
    },
    fileSize: {
        color: 'rgba(0, 0, 0, 0.55)',
        fontSize: '0.8rem',
        marginLeft: '5px'
    }
}));

export const FileUploadList = ({files = [], onRemove}) => {
    const styles = useStyles()

    if(files === undefined) {
        return null
    }

    return (
        <ul className={styles.fileList}>
            {Object.values(files).map(uploadFile => {

                const {file} = uploadFile
                const FileIcon = getFileIcon(file.type);

                return <li key={uploadFile.id}>
                        <div className={styles.fileInfo}>
                            <Avatar className={styles.blue}>
                                <IconContext.Provider value={{color: styles.icon.color}}>
                                    <FileIcon className={styles.icon}/>
                                </IconContext.Provider>
                            </Avatar>
                            <span className={styles.fileName}>{file.name}</span>
                            <span className={styles.fileSize}>{sizeInWords(file.size)}</span>
                            {uploadFile.status === UPLOAD_STATE.COMPLETE ? <Check color={'primary'}/> : null}
                            {onRemove !== undefined && <IconButton edge="end" aria-label="delete" onClick={() => {onRemove(uploadFile)}}>
                                <DeleteIcon />
                            </IconButton>}
                        </div>

                    {uploadFile.status !== UPLOAD_STATE.COMPLETE && <LinearProgressBar value={uploadFile.progress}/>}
                </li>
            })}
        </ul>
    )
}