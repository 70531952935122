import React from 'react'
import {Controller, useFormContext} from "react-hook-form";
import {AsyncSelect} from "./AsyncSelect";
import RHFErrorMessage from "./RHFErrorMessage";
import InputLabel from "@material-ui/core/InputLabel";


export const RHFAsyncSelect = ({name, label, path, ...rest}) => {
    const {control, formState: { errors }} = useFormContext();

    const labelId = `${name}-label`;

    return <>
        <InputLabel
            id={labelId}
            error={errors?.name}
        >{label}
        </InputLabel>
        <Controller
            render={({
                         field: { onChange, onBlur, value, name, ref },
                         fieldState: { invalid, isTouched, isDirty, error },
                         formState
                     }) => {

                return <AsyncSelect
                    path={path}
                    onChange={onChange}
                    value={value}
                    selectOption={(option) => {
                        if(option) {
                            return option.id;
                        }
                        return '';
                    }}
                    getOptionLabel={option =>`${option.name}`}
                    getOptionValue={option =>`${option.id}`}
                    {...rest}
                />
            }}
            name={name}
            control={control}
        />
        <RHFErrorMessage errors={errors} name={name} />
    </>
}
