export default class Deferred {

    promise = null
    reject = () => {}
    resolve = () => {}

    constructor(handler) {

        this.promise = new Promise((resolve, reject) => {
            this.reject = reject
            this.resolve = resolve
            if(handler) {
                handler(resolve, reject)
            }
        });

        this.promise.resolve = this.resolve;
        this.promise.reject = this.reject;

        return this.promise;
    }
}