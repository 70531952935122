import React, {useState} from 'react'
import {useParams} from "react-router-dom";
import {mutations, queries as electionQueries} from "../../api/query/election";
import {queries as candidateQueries} from "../../api/query/candidate";
import BackendContent from "../../layout/BackendContent";
import LoadingOverlay from "../../layout/LoadingOverlay";
import {Toolbar} from "../../layout/toolbar/Toolbar";
import Election from "../../components/election/Election";
import {CardContent, Grid} from "@material-ui/core";
import {Card} from "../../layout/StyledComponent";
import CandidateTable from "./CandidateTable";
import {ServerPagination} from "../../components/list/Pagination";
import ActionButton from "../../layout/ActionButton";
import styled from "styled-components";
import Form from "./Form";

const CandidateToolbar = styled(Toolbar)`
    margin-top: 20px;
`

const FormWrapper = styled.div`
    margin-top: 20px;
`

export const ElectionView = () => {

    const {electionId} = useParams();
    const [query, setQuery] = useState({})

    const {data: election, electionIsLoading} = electionQueries.useFetch({id: electionId})
    const {data: candidates, candidatesIsLoading, pagination} = candidateQueries.useList({electionId, params: query})
    const {mutate: mutateUpdate, isLoading: updating} = mutations.useUpdate()

    const isLoading = electionIsLoading || candidatesIsLoading

    const handleSubmit = (formData) => {
        mutateUpdate({
            data: {
                ...election,
                ...formData
            }
        })
    }

    return (
        <BackendContent>
            <LoadingOverlay open={isLoading} />

            <Toolbar title={'Onlinewahl Übersicht'}>
                <ActionButton to={'/election'}>
                    Zur Liste
                </ActionButton>
            </Toolbar>

            <Election election={election} />

            {election !== undefined && <FormWrapper>
                <Form election={election} onSubmit={handleSubmit} pending={updating} />
            </FormWrapper>}

            <CandidateToolbar title={'Kandidaten'}>
                <ActionButton to={`/election/${electionId}/candidate/create`}>
                    Neuen Kandidat anlegen
                </ActionButton>
            </CandidateToolbar>

            <Grid container>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <CandidateTable
                                candidates={candidates}
                                pagination={
                                    <ServerPagination data={pagination} colSpan={5} onChangePage={(event, newPage) => {
                                        setQuery({
                                            limit: pagination.maxPerPage,
                                            offset: newPage + 1
                                        })
                                    }}/>
                                }
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </BackendContent>
    )
}

export default ElectionView