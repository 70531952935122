import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

export const CircularProgressBar = ({value = 0, ...props}) => {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" value={value} {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

CircularProgressBar.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};


export const LinearProgressBar = ({value = 0, ...props}) => {

    return (
        <Box display="flex" alignItems="center" >
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" value={value} {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(value)}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressBar.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};