import React from 'react'
import {Line} from "react-chartjs-2";
import styled from "styled-components";

const options = {

    responsive: false,
    maintainAspectRatio: false,
    scales: {
        y: {
            title: {
                display: true,
                text: 'Stimmen'
            },
            beginAtZero: true,
            ticks: {
                stepSize: 25,
                maxRotation: 90,
                minRotation: 90

            },
            maintainAspectRatio: false,
            suggestedMax: 100
        },
        x: {
            title: {
                display: true,
                text: 'Wahlzeitraum'
            },
            ticks: {
                stepSize: 1,
                min: 1,
                maxRotation: 90,
                minRotation: 90
            },
            maintainAspectRatio: false,
        },
    }
}

const CanvasWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 600px;
    overflow-x: scroll;

    > canvas {
      position: absolute;
      left: 0;
      top: 0;
      pointer-events: none;
    }

`;

export const VotingsStatistic = ({statistic}) => {

    if(statistic === undefined) {
        return null
    }

    statistic.datasets[0].fill = false;
    statistic.datasets[0].backgroundColor = ['rgb(200,206,174)'];

    return (

        <CanvasWrapper>
            <Line  width={2048} height={500} data={statistic} options={options} />
        </CanvasWrapper>

    )
}

export default VotingsStatistic