import React from "react";
import CardHeader from "@material-ui/core/CardHeader";
import {CardContent} from "@material-ui/core";
import {Card} from "../../layout/StyledComponent";

const FormCard = ({title, action, children}) => {
    return <Card mb={6}>
        {(title || action) && <CardHeader title={title} action={action}/>}
        <CardContent>
            {children}
        </CardContent>
    </Card>
};
export default FormCard;