import axios from 'axios';
import authService from "../components/auth/authService";

const API_BASE_URL = process.env.REACT_APP_WEBSITE_URL;
const API_BACKEND_URL = API_BASE_URL + '/api/backend'
const APP_AUTHORIZATION_HEADER_NAME = 'OW-Authorization'
const AUTHORIZATION_HEADER_PREFIX = 'Bearer'

const headers = {};
const instance = axios.create({
    baseURL: API_BACKEND_URL,
    headers
});

instance.interceptors.request.use((requestConfig) => {
    const jwt = authService.getAccessToken();
    if (jwt) {
        requestConfig.headers[APP_AUTHORIZATION_HEADER_NAME] = AUTHORIZATION_HEADER_PREFIX + ' ' + jwt;
    }
    return requestConfig;
}, (error) => {
    return Promise.reject(error);
});

instance.interceptors.response.use(res => res, error => {
    // Bei Fehlern mit Status 4XX interessiert uns die Reponse, da üblicherweise eine sinnvolle Fehlermeldung
    // enthalten ist, die wir auch anzeigen können.

    // Benutzer nicht mehr authentifiziert
    if(error.response.status === 401) {
        authService.reset();
        authService.loginRedirect();
    }

    if (error.response.status < 500) {
        return Promise.reject({
            data: error.response.data,
            status: error.response.status
        });
    }
    return Promise.reject(error);
});

export default instance