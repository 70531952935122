import React from 'react'
import LoadingOverlay from "../../layout/LoadingOverlay";
import {Toolbar} from "../../layout/toolbar/Toolbar";
import BackendContent from "../../layout/BackendContent";
import {useParams} from "react-router-dom";
import {queries} from "../../api/query/statistics";
import GeneralStatistic from "./GeneralStatistic";
import StatisticHeader from "./StatisticHeader";

export const GeneralView = () => {
    const {electionId} = useParams()

    const {data: candidateStatistics, isLoading} = queries.useFetchCandidates({electionId})

    return (
        <BackendContent>
            <LoadingOverlay open={isLoading}/>

            <Toolbar title={'Statistiken'} />
            <StatisticHeader />
            <GeneralStatistic statistic={candidateStatistics} />

        </BackendContent>
    )
}

export default GeneralView