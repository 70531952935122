import {useMutation} from "react-query";
import axios from "../index";

const useSearch = () => {

    return useMutation(async ({electionId, tanCode}) => {

        return axios.post(`/election/${electionId}/tanCode/search`, {tanCode}, {
        }).then((response) => {
            return response.data === '' ? null : response.data
        });
    });
}

export const mutations = {
    useSearch
}