import React from 'react'
import BackendContent from "../../layout/BackendContent";
import LoadingOverlay from "../../layout/LoadingOverlay";
import {useParams} from "react-router-dom";
import {mutations, queries} from "../../api/query/snippet";
import {Toolbar} from "../../layout/toolbar/Toolbar";
import BackButton from "../../layout/ActionButton";
import FeedbackButton from "../../components/form/FeedbackButton";
import Form from "./Form";

export const UpdateView = () => {
    const {snippetId, electionId} = useParams()
    const {data: snippet, isLoading} = queries.useFetch({electionId, id: snippetId})

    const {mutate: mutateUpdate, isLoading: updating} = mutations.useUpdate()

    const backLink = `/election/${electionId}/snippet`

    return (
        <BackendContent>
            <LoadingOverlay open={isLoading}/>

            <Toolbar title={'Textbereich bearbeiten'}>
                <FeedbackButton form="snippetForm" type="submit" pending={updating}>
                    speichern
                </FeedbackButton>

                <BackButton to={backLink}>
                    Zur Liste
                </BackButton>
            </Toolbar>

            {snippet && <Form
                onSubmit={(formData) => {
                    const data = {...snippet, ...formData};
                    mutateUpdate({electionId, data})
                }}
                backLink={backLink}
                pending={updating}
                snippet={snippet}
            />}
        </BackendContent>
    )
}

export default UpdateView