import {createUseList} from "./list";
import {createUseFetch} from "./fetch";
import {createUseCreate} from "./create";
import {createUseUpdate} from "./update";
import {createUseDelete} from "./delete";


export const createQueries = (name) => {
    return {
        useList: createUseList(name),
        useFetch: createUseFetch(name)
    }
}

export const createMutations = (name) => {
    return {
        useCreate: createUseCreate(name),
        useUpdate: createUseUpdate(name),
        useDelete: createUseDelete(name)
    }
}