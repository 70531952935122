import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import Sidebar from "../sidebar/Sidebar";
import Header from "../Header";
import {Paper} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import {Footer} from "../Footer";

const Wrapper = styled.div`
    display: flex;
    min-height: 100vh;
    height: 100vh;
`;

const Main = styled.main`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
`;

const ContentWrapper = styled(Paper)`
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    background: rgb(249, 249, 252);
`;

const Content = styled(Paper)`
    flex: 1
`;

//locale={datepickerLocale}
const BackendArea = ({children}) => {
    return (
        <Wrapper>
            <Sidebar/>
            <Main>
                <Header/>
                <ContentWrapper>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Content>
                            {children}
                        </Content>
                    </MuiPickersUtilsProvider>
                    <Footer/>
                </ContentWrapper>
            </Main>
        </Wrapper>
    )
};

BackendArea.propTypes = {
    children: PropTypes.node.isRequired
};
export default BackendArea;