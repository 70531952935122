import React, {useEffect, useMemo} from "react";
import * as yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {DevTool} from "@hookform/devtools";
import FormCard from "../../components/form/FormCard";
import {Grid} from "@material-ui/core";
import RHFTextField from "../../components/form/RHFTextField";
import FeedbackButton from "../../components/form/FeedbackButton";
import {ActionButton} from "../../layout/ActionButton";

const schema = yup.object().shape({
    name: yup.string().required('Bitte ausfüllen')
});

export const Form = ({district, pending, backLink, onSubmit}) => {

    const formMethods = useForm({
        resolver: yupResolver(schema),
        defaultValues: useMemo(() => {
            if (district !== undefined) {
                return district
            }

            return {
                'name': ''
            };
        }, [district])
    });

    const {handleSubmit, reset, control} = formMethods;

    useEffect(function() {
        if(district !== undefined) {
            reset(district)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [district]);

    return (
        <FormProvider {...formMethods}>
            <DevTool control={control}/>
            <form id="districtForm" onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormCard title={'Stammdaten'}>
                    <Grid container spacing={6} >

                        <Grid item xs={12} sm={6}>
                            <RHFTextField
                                name="name"
                                label="Stadtteil"
                            />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent={'flex-end'} item xs={12}>

                        <FeedbackButton type="submit" pending={pending}>
                            speichern
                        </FeedbackButton>

                        <ActionButton to={backLink}>
                            Zurück
                        </ActionButton>
                    </Grid>

                </FormCard>
            </form>
        </FormProvider>
    )
}

export default Form