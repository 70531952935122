import {createUseList} from "./entity/list";
import {createUseFetch} from "./entity/fetch";
import {createUseUpdate} from "./entity/update";

const name = 'snippet';

export const queries = {
    useList: createUseList(name),
    useFetch: createUseFetch(name)
}

export const mutations = {
    useUpdate: createUseUpdate(name)
}