import React from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {IconButton} from "@material-ui/core";

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

/**
 *
 * @param count
 * @param page
 * @param rowsPerPage
 * @param onChangePage
 * @returns {JSX.Element}
 * @constructor
 */
function TablePaginationActions({ count, page, rowsPerPage, onChangePage }) {
    const classes = useStyles1();
    const theme = useTheme();

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

/**
 * @param pagination
 * @returns {JSX.Element}
 * @constructor
 */
export const Pagination = ({onChangePage, ...rest}) => {

    return <TablePagination
        rowsPerPageOptions={[]}
        labelDisplayedRows={({ from, to, count }) => (`${from} - ${to} von ${count}`)}
        {...rest}
        onChangePage={onChangePage}
        // onChangeRowsPerPage={onChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
    />
}

export const ServerPagination = ({data, ...rest}) => {
    if(!data?.paginate) {
        return null;
    }

    return <Pagination
        count={data.count}
        page={data.page - 1}
        rowsPerPage={data.maxPerPage}
        {...rest}
    />
}