import React from 'react'
import BackendContent from "../../layout/BackendContent";
import LoadingOverlay from "../../layout/LoadingOverlay";
import {Toolbar} from "../../layout/toolbar/Toolbar";
import {queries} from "../../api/query/statistics";
import {useParams} from "react-router-dom";
import StatisticHeader from "./StatisticHeader";
import SchoolStatistics from "./SchoolStatistics";
import {Typography} from "@material-ui/core";
import SchoolTable from "./SchoolTable";

export const SchoolView = () => {
    const {electionId} = useParams()
    const {data: schoolStatistics, isLoading} = queries.useFetchSchools({electionId})

    return (
        <BackendContent>
            <LoadingOverlay open={isLoading}/>
            <Toolbar title={'Statistiken'} />
            <StatisticHeader />
            <Typography variant="h4" gutterBottom component="div">Stimmabgabe nach Schulen</Typography>
            <SchoolStatistics statistic={schoolStatistics} />
            <SchoolTable statistic={schoolStatistics} />

        </BackendContent>
    )
}

export default SchoolView