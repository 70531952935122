import axios from '../../index'
import { useQuery} from "react-query";

export const createUseFetch = (name) => ({electionId, id}) => {

    return useQuery([name, electionId, parseInt(id)], async () => {
        try {
            return await axios.get(`/election/${electionId}/${name}/${id}`).then((response) => response.data);
        } catch (err) {
            throw err;
        }
    })
}