import React from "react";
import {Controller, useFormContext} from "react-hook-form";

import {FileUploadInput} from "../upload/FileUploadInput";

export const RHFTemporaryUploadFile = ({name, label, ...rest}) => {
    const {control} = useFormContext();

    return <Controller
        name={name}
        control={control}
        defaultValue={''}
        render={({
                     field: {onChange, onBlur, value, name, ref},
                     fieldState: {invalid, isTouched, isDirty, error},
                     formState
                 }) => {

                const handleRemove = (uploadFileState, uploadFile) => {
                    onChange(Object.values(uploadFileState).map(file => {
                        return file.response[0]
                    }))
                }

            return <FileUploadInput
                name={name}
                path={'/temporaryFile/upload'}
                onComplete={(data) => {
                    onChange(data.map(item => {
                        const {response} = item
                        return response[0]
                    }))
                }}
                onRemove={handleRemove}
                {...rest}
            />
            }
        }

    />

}