import React from 'react';
import PropTypes from 'prop-types';
import {Route} from "react-router-dom";

const LayoutRoute = ({layout: Layout, component: Component, ...rest}) => {
    return (
        <Route {...rest}>
            <Layout>
                <Component/>
            </Layout>
        </Route>
    )
};
LayoutRoute.propTypes = {
    layout: PropTypes.func.isRequired
};
export default LayoutRoute;