import React from 'react'
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";

export const GenericStatisticTable = ({statistic}) => {

    if(statistic === undefined) {
        return null
    }

    const items = statistic.table || []

    return (
        <TableContainer className={'sam-table'}>
            <Table size={'small'}>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Prozent</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => (
                        <TableRow key={item.name}>
                            <TableCell>
                                {item.name}
                            </TableCell>
                            <TableCell>
                                {item.percent}%
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default GenericStatisticTable