import React from 'react'
import {Doughnut} from "react-chartjs-2";

const colors = [
    'rgb(255, 99, 132)',
    'rgb(54, 162, 235)',
    'rgb(255, 205, 86)',
    'rgb(54, 162, 86)',
    'rgb(132, 54, 99)',
    'rgba(99,255,86,0.13)',
    'rgba(255,252,4,0.97)',
    'rgb(200,206,174)'
];

// const data = {
//     labels: [
//         'Red',
//         'Blue',
//         'Yellow'
//     ],
//     datasets: [{
//         label: 'My First Dataset',
//         data: [300, 50, 100],
//         backgroundColor: [
//             'rgb(255, 99, 132)',
//             'rgb(54, 162, 235)',
//             'rgb(255, 205, 86)'
//         ],
//         hoverOffset: 4
//     }]
// };

function getColors(labelCount) {
    let data = [];
    for(let i = 0; i < labelCount; i++) {
        data.push(colors[i]);
    }
    return data;
}

export const Pie = ({statistic}) => {

    if(statistic === undefined) {
        return null
    }

    statistic.datasets[0].backgroundColor =  getColors(statistic.labels.length);

    return (
        <Doughnut data={statistic} />
    )
}

export default Pie