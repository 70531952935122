import {createMutations, createQueries} from "./entity";

const name = 'school';

export const queries = {
    ...createQueries(name),

}

export const mutations = {
    ...createMutations(name)
}