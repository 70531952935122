import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "../../index";
import createUploadFile, {UPLOAD_STATE} from "../../../components/upload/factory";
import Deferred from "../../../util/promise";
import {toast} from "react-hot-toast";

const createOnUploadProgress = (uploadFile, uploadCacheKey, queryClient) => (event) => {

    const progress = Math.round(event.lengthComputable ? event.loaded * 100 / event.total : 0);
    const secondsElapsed = (new Date().getTime() - uploadFile.createdAt.getTime()) / 1000;
    const remainingBytes = event.total - event.loaded;
    const bytesPerSecond = Math.floor(event.loaded / secondsElapsed);
    const secondsRemaining = Math.floor(remainingBytes / bytesPerSecond);

    if (progress >= 100) {
        uploadFile.status = UPLOAD_STATE.COMPLETE
    }

    queryClient.setQueryData(uploadCacheKey, prevData => {
        return {
            ...prevData,
            [uploadFile.id]: {
                ...uploadFile,
                progress,
                secondsRemaining,
                remainingBytes
            }
        }
    });
}

export const createUseFileUploader = (cacheName, path, onSuccess, toastOptions = {}) => () => {
    const queryClient = useQueryClient();
    const uploadCacheKey = `${cacheName}FileUpload`;

    const mutation = useMutation(async ({id, files}) => {

        const uploadFiles = {};
        Array.from(files).forEach(file => {
            const uploadFile = createUploadFile(file)
            return uploadFiles[uploadFile.id] = uploadFile
        })
        queryClient.setQueryData(uploadCacheKey, uploadFiles)

        return Promise.all(Object.values(uploadFiles).map((uploadFile) => {
            const {file} = uploadFile;

            const formData = new FormData();
            formData.append("file", file, file.name);

            const filePath = path.replace(':id', id)

            try {
                return axios.post(`${filePath}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: createOnUploadProgress(uploadFile, uploadCacheKey, queryClient)
                }).then((response) => {
                    return {
                        response: response.data,
                        uploadFile
                    }
                });
            } catch (err) {
                throw err;
            }

        }))
    }, {
        onMutate: async ({id, files}) => {

            const toastPromise = new Deferred();
            const fileCount = files.length
            toast.promise(toastPromise, {
                loading: `Es sollen ${fileCount} Datei(en) hochgeladen werden`,
                success: "Die Datei(en) wurden erfolgreich hochgeladen",
                error: "Die Datei(en) konnten nicht hochgeladen werden",
                ...toastOptions
            });

            return {toastPromise, id: parseInt(id)}
        },
        onSuccess: (data, variables, context) => {
            onSuccess({data, variables, context, queryClient, cacheName, uploadCacheKey})
            context.toastPromise.resolve()
        },
        onError: ((error, variables, context) => {
            context.toastPromise.reject()
        })
    })

    const {data: uploadFiles} = useQuery([uploadCacheKey], () => {
        return queryClient.getQueryData(uploadCacheKey);
    })

    return {
        ...mutation,
        name: cacheName,
        uploadFiles
    }
}

export const createUseDeleteTemporaryUploadFile = (cacheName) => () => {
    const queryClient = useQueryClient();
    const uploadCacheKey = `${cacheName}FileUpload`;

    return useMutation(async ({fileId}) => {
        return new Promise((resolve, reject) => {
            const data = queryClient.getQueryData(uploadCacheKey);
            if(data !== undefined) {
                queryClient.setQueryData(uploadCacheKey, prevData => {
                    delete prevData[fileId]
                    return prevData
                })

                resolve(queryClient.getQueryData(uploadCacheKey))
            } else {
                reject('Cache not set and filled with files')
            }
        })
    }, {
        onSuccess: () => {
            toast.success('Datei wurde erfolgreich entfernt')
        }
    })
}