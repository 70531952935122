import React from 'react'
import {Check, Clear} from "@material-ui/icons";

export const BooleanToIcon = ({value}) => {

    if(value === true) {
        return <Check />
    }

    return <Clear />
}