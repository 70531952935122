import React, {useState} from 'react'
import api from "../../api";
import AsyncCreatableReactSelect from 'react-select/async-creatable';
import AsyncReactSelect from 'react-select/async';

export const AsyncSelect = ({value, onChange, path, isCreatable= false, ...rest}) => {

    const [query, setQuery] = useState('')

    const AsyncSelect = isCreatable ? AsyncCreatableReactSelect : AsyncReactSelect

    const loadOptions = () => {
        return api.get(path, { params: {search: query }}).then(function(response) {
            return response.data.results;
        });
    };

    return (
        <AsyncSelect
            isCreatable={isCreatable}
            cacheOptions
            defaultOptions
            loadOptions={loadOptions}
            noOptionsMessage={() => 'Kein Ergebnis gefunden'}
            loadingMessage={() => 'Lade...'}
            onInputChange={(value) => setQuery(value)}
            onChange={onChange}
            value={value}
            {...rest}
        />
    );
}