import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "../index";
import {usePagination} from "./entity/pagination";
import {isEmpty} from "lodash";
import {toast} from "react-hot-toast";

const name = 'election';

export const useList = ({params}) => {
    const queryClient = useQueryClient()
    const pagination = usePagination([name, 'pagination'])

    let queryKey = [name]
    if(params !== undefined && !isEmpty(params)) {
        queryKey.push(params)
    }

    return {
        pagination: {...pagination}, ...useQuery(queryKey, async () => {
            try {
                return await axios.get(`/${name}`, {params: params}).then((response) => {
                    queryClient.setQueryData([name, 'pagination'], response.data.pagination)
                    return response.data.results
                });
            } catch (err) {
                throw err;
            }
        })
    }
}

export const useFetch = ({id}) => {

    return useQuery([name, parseInt(id)], async () => {
        try {
            return await axios.get(`/${name}/${id}`).then((response) => response.data);
        } catch (err) {
            throw err;
        }
    })
}

export const queries = {
    useFetch,
    useList
}

const useUpdate = () => {
    const queryClient = useQueryClient()

    return useMutation(async ({data}) => {

        try {
            return await axios.put(`/${name}/${data.id}`, data).then((response) => {
                return response.data
            });
        } catch (err) {
            throw err;
        }
    }, {
        onSuccess: (data, variables, context) => {
            queryClient.setQueryData([name, data.id], data)
            toast.success("Der Datensatz wurde erfolgreich bearbeitet")
        },
        onError: (error, variables, context) => {
            toast.error("Der Datensatz konnte nicht bearbeitet werden")
        }
    })
}

export const mutations = {
    useUpdate
}