import {useQueryClient} from "react-query";

export const usePagination = (queryKey) => {
    const queryClient = useQueryClient()
    const paginationData = queryClient.getQueryData(queryKey)

    if(paginationData !== undefined) {
        return paginationData
    }

    return {
        count: 0,
        maxPerPage: 20,
        page: 1,
        pages: 1,
        paginate: false
    }
}