import React, {useEffect} from 'react';
import { useLocation, Redirect, useHistory } from 'react-router-dom';

import {FormHelperText} from '@material-ui/core';

import {Paper, Typography} from '../../layout/StyledComponent';
import Form from "./Form";
import authService, {DEFAULT_LOGIN_SUCCESS_REDIRECT_URL, LOGIN_URL} from "../../components/auth/authService";
import {mutations} from "../../api/query/auth";


const GlobalErrorMessage = ({error}) => {

    if(error === null) {
        return null;
    }


    if(error.status === 401) {
        return <FormHelperText error={true}>Zugangsdaten ungültig</FormHelperText>
    }

    return null;
}

const Login = () => {
    const history = useHistory()
    const location = useLocation()
    const {mutate: mutateAuth, data, isLoading, error, isSuccess} = mutations.useLogin()

    useEffect(() => {
        if(isSuccess) {
            authService.setAccessToken(data.token)
            history.push(authService.getLoginSuccessRedirectUrl())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess])

    if(location.pathname === LOGIN_URL && authService.isAuthenticated()) {
        return <Redirect to={DEFAULT_LOGIN_SUCCESS_REDIRECT_URL} />
    }

    const onSubmit = data => {
        mutateAuth(data)
    }

    return <Paper p={10}>
        <Typography align={'center'} variant={'h1'} gutterBottom={true} variantMapping={{'body1': 'h1'}} mb={4}>
            Onlinewahlen
        </Typography>

        <Typography align={'center'} variant={'body1'} gutterBottom={true} variantMapping={{'body1': 'h2'}} mb={2}>
            Loggen Sie sich in Ihren Account ein
        </Typography>

        <GlobalErrorMessage error={error} />

        <Form onSubmit={onSubmit} pending={isLoading} />

    </Paper>
}

export default Login;