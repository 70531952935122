import React from 'react'
import BackendContent from "../../layout/BackendContent";
import LoadingOverlay from "../../layout/LoadingOverlay";
import {useParams} from "react-router-dom";
import {mutations, queries} from "../../api/query/district";
import {Toolbar} from "../../layout/toolbar/Toolbar";
import BackButton from "../../layout/ActionButton";
import FeedbackButton from "../../components/form/FeedbackButton";
import Form from "./Form";
import ConfirmationButton from "../../layout/ConfirmationButton";

export const UpdateView = () => {
    const {districtId, electionId} = useParams()
    const {data: district, isLoading} = queries.useFetch({electionId, id: districtId})

    const {mutate: mutateUpdate, isLoading: updating} = mutations.useUpdate()
    const {mutate: mutateDelete, isLoading: deleting} = mutations.useDelete()

    const backLink = `/election/${electionId}/district`

    return (
        <BackendContent>
            <LoadingOverlay open={isLoading}/>

            <Toolbar title={'Stadtteil bearbeiten'}>
                <FeedbackButton form="districtForm" type="submit" pending={updating}>
                    speichern
                </FeedbackButton>

                <ConfirmationButton
                    dialogTitle={'Stadtteil löschen'}
                    dialogText={'Achtung! Der Stadtteil wird dann auch aus allen Kandidaten gelöscht!'}
                    onClick={() => {
                        mutateDelete({electionId: electionId, id: districtId})
                    }}
                    ButtonProps={{'ml': 2}}
                    pending={deleting}>
                    löschen
                </ConfirmationButton>

                <BackButton to={backLink}>
                    Zur Liste
                </BackButton>
            </Toolbar>

            {district && <Form
                onSubmit={(formData) => {
                    const data = {...district, ...formData};
                    mutateUpdate({electionId, data})
                }}
                backLink={backLink}
                pending={updating}
                district={district}
            />}
        </BackendContent>
    )
}

export default UpdateView