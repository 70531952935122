import React from 'react'
import {useHistory, useParams} from "react-router-dom";
import {Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {BooleanToIcon} from "../../layout/BooleanToIcon";
import Image from "../../layout/Image";

export const CandidateTable = ({candidates, pagination}) => {
    const history = useHistory();
    const {electionId} = useParams();

    if (candidates === undefined) {
        return null;
    }

    if (candidates.length === 0) {
        return (
            <Typography>Keine Ergebnisse</Typography>
        )
    }

    return (
        <TableContainer className={'sam-table'}>
            <Table size={'small'}>

                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Bild</TableCell>
                        <TableCell>Berufsstufe</TableCell>
                        <TableCell>Aktiv</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {candidates.map((candidate) => (
                        <TableRow key={candidate.id} onClick={() => {
                            history.push(`/election/${electionId}/candidate/${candidate.id}/update`);
                        }}>
                            <TableCell>
                                {candidate.firstname} {candidate.lastname}
                            </TableCell>
                            <TableCell>
                                <Image path={candidate.imagePath} width={100} height={100} />
                            </TableCell>
                            <TableCell>
                                {candidate.educationalLevel}
                            </TableCell>
                            <TableCell>
                                <BooleanToIcon value={candidate.active} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>

                <TableFooter>
                    <TableRow>
                        {pagination}
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}

export default CandidateTable