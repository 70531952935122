import React from 'react'
import {Link} from "react-router-dom";

export const FooterContent = () => {
    return (
        <ul className={"footer-navigation"}>
            <li>
                <Link to={'/imprint'}>Impressum</Link>
            </li>
            <li>
                <Link to={'/privacy'}>Datenschutz</Link>
            </li>
        </ul>
    )
}

export const Footer = () => {
    return (
        <div className={"footer-container"}>
            <div className={"footer"}>
                <FooterContent />
            </div>
        </div>
    )
}