import React from "react";
import {Controller, useFormContext} from "react-hook-form";
import {TextField} from "@material-ui/core";
import RHFErrorMessage from "./RHFErrorMessage";

const RHFTextField = ({name, label, defaultValue, ...rest}) => {

    const {control, formState: {errors}} = useFormContext();

    return <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
        render={({
                     field: { onChange, onBlur, value, name, ref },
                     fieldState: { invalid, isTouched, isDirty, error },
                     formState
                 }) => (
            <>
                <TextField
                name={name}
                label={label}
                error={!!error?.message}
                variant="outlined"
                margin="normal"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                {...rest}
                />
                <RHFErrorMessage errors={errors} name={name} />
            </>
        )}
    />
}

export default RHFTextField;