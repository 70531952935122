import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        fontSize: '1em'
    },
}))

export const Title = () => {
    const classes = useStyles();

    return (
        <Typography className={classes.title} variant="h1" noWrap>
            Onlinewahlen
        </Typography>
    )
}