import React from 'react';
import PropTypes from 'prop-types';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {NavLink, useParams} from 'react-router-dom'
import ExpandableMenuItem from "./ExpandableMenuItem";
import {ListSubheader} from "@material-ui/core";

const MenuItem = ({item}) => {
    const params = useParams()

    if (item.items && item.items.length !== 0) {
        return <ExpandableMenuItem item={item}/>
    }

    const Icon = item.Icon || null;
    let link = item.link

    if ('dependsOnParameters' in item) {
        for(const key of Object.keys(params)) {
            link = link.replace(`:${key}`, params[key])
        }
    }

    return (
        <>
            {item.header && <ListSubheader>{item.header}</ListSubheader>}
            <ListItem to={link} button component={NavLink} divider={item.divider || false}>
                {Icon && (
                    <ListItemIcon>
                        <Icon/>
                    </ListItemIcon>
                )}
                <ListItemText primary={item.name} inset={!Icon}/>
            </ListItem>
        </>
    )
};

MenuItem.propTypes = {
    item: PropTypes.object.isRequired
};
export default MenuItem;