import React, {useState} from 'react';
import {
    CardContent, Grid, Card
} from "@material-ui/core";
import LoadingOverlay from "../../layout/LoadingOverlay";
import {ServerPagination} from "../../components/list/Pagination";

import BackendContent from "../../layout/BackendContent";

import {Toolbar} from "../../layout/toolbar/Toolbar";
import {SchoolTable} from "./SchoolTable";
import {queries} from "../../api/query/school";
import {useParams} from "react-router-dom";
import ActionButton from "../../layout/ActionButton";



const ListView = () => {
    const {electionId} = useParams()
    const [query, setQuery] = useState({})

    const {data: schools, isLoading, pagination} = queries.useList({electionId, params: query})

    return <BackendContent>
        <LoadingOverlay open={isLoading}/>

        <Toolbar title={'Schulen'}>
            <ActionButton to={`/election/${electionId}/school/create`}>
                Neue Schule anlegen
            </ActionButton>
        </Toolbar>

        <p>
            Diese Schulen stehen dem Wähler als optional Auswahl bei Wahl des Kandidaten zur Verfügung.<br />
        </p>

        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Card mb={6}>
                    <CardContent>
                        <SchoolTable
                            schools={schools}
                            electionId={electionId}
                            pagination={
                                <ServerPagination data={pagination} colSpan={3} onChangePage={(event, newPage) => {
                                    setQuery({
                                        limit: pagination.maxPerPage,
                                        offset: newPage + 1
                                    })
                                }}/>
                            }
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </BackendContent>;
};

export default ListView;