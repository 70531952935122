import React from "react";
import * as yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {DevTool} from "@hookform/devtools";
import {Grid} from "@material-ui/core";
import RHFTextField from "../../components/form/RHFTextField";
import FormCard from "../../components/form/FormCard";
import FeedbackButton from "../../components/form/FeedbackButton";

const schema = yup.object().shape({
    tanCode: yup.string().required('Bitte ausfüllen')
});

export const Form = ({onSubmit, pending}) => {

    const formMethods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            tanCode: ''
        }
    });

    const {handleSubmit, control} = formMethods;

    return (
        <FormProvider {...formMethods}>
            <DevTool control={control}/>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormCard>
                    <Grid container spacing={6} >

                        <Grid item xs={12}>
                            <RHFTextField
                                name="tanCode"
                                label="Tan"
                            />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent={'flex-end'} item xs={12}>

                        <FeedbackButton type="submit" pending={pending}>
                            Suchen
                        </FeedbackButton>
                    </Grid>
                </FormCard>
            </form>
        </FormProvider>
    )
}

export default Form