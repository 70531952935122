import React from 'react'
import {FileUploadInput} from "../../../components/upload/FileUploadInput";
import {useQueryClient} from "react-query";

export const CandidateImageUpload = ({candidate, electionId}) => {
    const queryClient = useQueryClient()

    const handleComplete = (data) => {
        const {response} = data[0]
        queryClient.setQueryData(['candidate', electionId, response.id], response)
    }

    return (
        <FileUploadInput
            path={`/election/${electionId}/candidate/${candidate.id}/image`}
            name={'file'}
            multiple={false}
            droptext={'Hier das Bild des/der Kandidaten/in ablegen oder klicken, um eins anzuhängen'}
            accept={'image/*'}
            onComplete={handleComplete}
            clearAfterUpload={true}
        />
    )
}

export default CandidateImageUpload