import {Switch} from "react-router-dom";
import LayoutRoute from "../../layout/router/LayoutRoute";
import BackendArea from "../../layout/templates/BackendArea";
import MiddleContent from "../../layout/templates/MiddleContent";
import Error404 from "../system/Error404";
import GeneralView from "./GeneralView";
import VotingView from "./VotingView";
import OtherView from "./OtherView";
import SchoolView from "./SchoolView";


const Routing = () => {
    return (
        <>
            <Switch>
                <LayoutRoute layout={BackendArea} exact path="/election/:electionId/statistics/voting" component={VotingView}/>
                <LayoutRoute layout={BackendArea} exact path="/election/:electionId/statistics/school" component={SchoolView}/>
                <LayoutRoute layout={BackendArea} exact path="/election/:electionId/statistics/other" component={OtherView}/>
                <LayoutRoute layout={BackendArea} exact path="/election/:electionId/statistics" component={GeneralView}/>

                <LayoutRoute path="*" layout={MiddleContent} component={Error404}/>
            </Switch>
        </>
    )
};
export default Routing;