import React from 'react'
import {Avatar} from "@material-ui/core";

const API_URL = process.env.REACT_APP_WEBSITE_URL

export const Image = ({path, width = 200, height= 200}) => {

    return (
        <Avatar variant={"rounded"} alt="" src={`${API_URL}${path}`} style={{
            width,
            height,
        }} />
    )
}

export default Image