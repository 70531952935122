import {useHistory} from "react-router-dom";
import {Typography} from "../../layout/StyledComponent";
import {Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from "@material-ui/core";
import * as moment from "moment";
import React from "react";

export const DistrictTable = ({districts, electionId, pagination}) => {
    const history = useHistory();

    if(districts === undefined) {
        return null;
    }

    if(districts.length === 0) {
        return <Typography>Keine Ergebnisse</Typography>
    }

    return (
        <TableContainer className={'sam-table'}>
            <Table size={'small'}>

                <TableHead>
                    <TableRow>
                        <TableCell>Stadtteil</TableCell>
                        <TableCell>Erstellt am</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {districts.map((district) => (
                        <TableRow key={district.id} onClick={() => {
                            history.push(`/election/${electionId}/district/${district.id}/update`);
                        }}>
                            <TableCell>
                                {district.name}
                            </TableCell>
                            <TableCell>
                                {moment(district.createdAt).format('DD. MMMM YYYY HH:mm')} Uhr
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        {pagination}
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}