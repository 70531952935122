import axios from '../../index'
import {useMutation, useQueryClient} from "react-query";
import {toast} from "react-hot-toast";

export const createUseUpdate = (name) => () => {
    const queryClient = useQueryClient()

    let tmpElectionId = null

    return useMutation(async ({electionId, data}) => {
        tmpElectionId = electionId

        try {
            return await axios.put(`/election/${electionId}/${name}/${data.id}`, data).then((response) => {
                return response.data
            });
        } catch (err) {
            throw err;
        }
    }, {
        onSuccess: (data, variables, context) => {
            queryClient.setQueryData([name, tmpElectionId, data.id], data)
            toast.success("Der Datensatz wurde erfolgreich bearbeitet")
        },
        onError: (error, variables, context) => {
            toast.error("Der Datensatz konnte nicht bearbeitet werden")
        }
    })
}