import axios from '../../index'
import {useMutation, useQueryClient} from "react-query";
import {toast} from "react-hot-toast";
import {useHistory} from "react-router-dom";

export const createUseCreate = (name) => () => {
    const history = useHistory();
    const queryClient = useQueryClient()

    let tmpElectionId = null;

    return useMutation(async ({electionId, data}) => {

        tmpElectionId = electionId
        try {
            return await axios.post(`/election/${electionId}/${name}`, data).then((response) => {
                return response.data
            });
        } catch (err) {
            throw err;
        }
    }, {
        onSuccess: (data, variables, context) => {
            toast.success("Der Datensatz wurde erfolgreich hinzugefügt")
            queryClient.setQueryData([name, tmpElectionId, data.id], data)
            history.push(`/election/${tmpElectionId}/${name}/${data.id}/update`)
        },
        onError: (error, variables, context) => {
            toast.error("Der Datensatz konnte nicht hinzugefügt werden")
        }
    })
}