import React from 'react'
import {Bar} from "react-chartjs-2";

const data = {
    labels: [
        'Red',
        'Blue',
        'Yellow'
    ],
    datasets: [{
        label: 'My First Dataset',
        data: [300, 50, 100],
        backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)'
        ],
        hoverOffset: 4
    }]
};

const options = {
    legend: { display: false },
    title: {
        display: false,
    }
};

export const SchoolStatistics = ({statistic}) => {

    if(statistic === undefined) {
        return null
    }

    statistic.datasets[0].backgroundColor = ['rgb(200,206,174)'];

    return (
        <Bar data={statistic} options={options} />
    )
}

export default SchoolStatistics