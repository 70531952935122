import React from 'react';
import PropTypes from 'prop-types';
import BaseBreadcrumbs from '@material-ui/core/Breadcrumbs';
import {has} from 'lodash';

import {Typography} from "./StyledComponent";
import {Link as RouterLink} from "react-router-dom";
import Link from "@material-ui/core/Link";

const Breadcrumbs = ({items = []}) => {

    return (
        <BaseBreadcrumbs>
            {items.map((item, index) => {

                if(item.name === undefined) {
                    return null
                }

                if(has(item, 'link')) {
                    return <Link to={item.link} component={RouterLink} key={index}>{item.name}</Link>
                }

                return <Typography key={index}>{item.name}</Typography>
            })}
        </BaseBreadcrumbs>
    )
};
Breadcrumbs.propTypes = {
    items: PropTypes.array.isRequired
};
export default Breadcrumbs;