import React, {useEffect, useMemo} from "react";

import {useForm, FormProvider} from "react-hook-form";
import {CardActions, CardContent, CardHeader, Grid} from "@material-ui/core";
import FeedbackButton from "../../components/form/FeedbackButton";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import {DevTool} from '@hookform/devtools';

import RHFTextField from "../../components/form/RHFTextField";
import FormCard from "../../components/form/FormCard";

import RHFSwitch from "../../components/form/RHFSwitch";
import {ActionButton} from "../../layout/ActionButton";
import RHFGenderField from "./form/RHFGenderField";
import {Card} from "../../layout/StyledComponent";
import Image from "../../layout/Image";
import {mutations} from "../../api/query/candidate";
import {useParams} from "react-router-dom";
import RHFDatePicker from "../../components/form/RHFDatePicker";
import {RHFAsyncSelect} from "../../components/form/RHFAsyncSelect";
import {RHFTemporaryUploadFile} from "../../components/form/RHFTemporaryUploadFile";
import CandidateImageUpload from "./form/CandidateImageUpload";
import moment from "moment/moment";

const schema = yup.object().shape({
    firstname: yup.string().required('Bitte ausfüllen'),
    lastname: yup.string().required('Bitte ausfüllen'),
    gender: yup.string().required('Bitte ausfüllen'),
    educationalLevel: yup.string().required('Bitte ausfüllen'),
    district: yup.object().shape({
        id: yup.number()
    }).required('Bitte ausfüllen'),
    hobbies: yup.string().required('Bitte ausfüllen'),
    active: yup.bool(),
    birthday: yup.date().required('Bitte ausfüllen'),
    file: yup.array().of(yup.string()),
});

const formDefaults = {
    firstname: '',
    lastname: '',
    gender: '',
    district: null,
    school: null,
    educationalLevel: '',
    hobbies: '',
    birthday: null,
    active: true,
    file: []
}

const Form = ({onSubmit, pending, candidate, backLink}) => {
    const {electionId} = useParams()
    const {mutate: mutateRemoveImage, isLoading: removingImage} = mutations.useRemoveImage()

    const formMethods = useForm({
        resolver: yupResolver(schema),
        defaultValues: useMemo(() => {
            if (candidate !== undefined) {
                return candidate
            }

            return formDefaults;
        }, [candidate])
    });

    const {handleSubmit, reset, control} = formMethods;

    useEffect(function () {
        if (candidate !== undefined) {
            reset(candidate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [candidate]);

    const handleRemoveImage = () => {
        mutateRemoveImage({electionId, id: candidate.id})
    }

    return <FormProvider {...formMethods}>
        <DevTool control={control}/>
        <form id="candidateForm" onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormCard title={'Stammdaten'}>
                <Grid container spacing={6}>

                    <Grid item xs={12} sm={6}>
                        <RHFTextField
                            name="firstname"
                            label="Vorname"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <RHFTextField
                            name="lastname"
                            label="Nachname"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <RHFDatePicker
                            name="birthday"
                            label="Geburtstag"
                            disableFuture
                            openTo="date"
                            format="DD.MM.YYYY"
                            views={["date", "month", "year"]}
                            defaultValue={moment('2005-01-01')}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <RHFGenderField
                            name="gender"
                            label="Geschlecht"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <RHFAsyncSelect
                            name="district"
                            label="Stadtteil"
                            path={`/election/${electionId}/district`}
                            menuPortalTarget={document.body}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <RHFTextField
                            name="educationalLevel"
                            label="Bildungsstufe"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <RHFTextField
                            name="hobbies"
                            label="Hobbies"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <RHFTextField
                            name="manifesto"
                            label="Veränderungswünsche"
                            multiline
                            minRows={4}
                            maxRows={6}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <RHFSwitch
                            name="active"
                            label="Zeige den Kandidaten an"
                        />
                    </Grid>

                </Grid>
            </FormCard>

            <Grid container spacing={6}>
                <Grid item xs={candidate?.imagePath === null ? 12 : 6}>
                    <Card mb={6}>
                        <CardHeader title={'Bilddatei'}/>
                        <CardContent>
                            {candidate === undefined ?
                            <RHFTemporaryUploadFile
                                name={'file'}
                                droptext={'Hier das Bild des/der Kandidaten/in ablegen oder klicken, um eins anzuhängen'}
                                accept={'image/*'}
                                multiple={false}
                             /> :
                            <CandidateImageUpload
                                candidate={candidate}
                                electionId={electionId}
                            />}

                        </CardContent>
                    </Card>
                </Grid>
                {candidate?.imagePath &&
                <Grid item xs={6}>
                    <Card mb={6}>
                        <CardContent>
                            <Image path={candidate.imagePath}/>
                        </CardContent>
                        <CardActions>
                            <FeedbackButton onClick={handleRemoveImage} variant={'outlined'} pending={removingImage}>Bild löschen</FeedbackButton>
                        </CardActions>
                    </Card>

                </Grid>
                }
            </Grid>

            <Grid container justifyContent={'flex-end'} item xs={12}>

                <FeedbackButton type="submit" pending={pending}>
                    speichern
                </FeedbackButton>

                <ActionButton to={backLink}>
                    Zurück
                </ActionButton>
            </Grid>
        </form>

    </FormProvider>
}

export default Form;