import React from "react";
import styled from 'styled-components';
import BaseBackdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const Backdrop = styled(BaseBackdrop)`
    z-index: 10000;
    color: #ffffff;
`;

const LoadingOverlay = ({open = false}) => {
    return (
        <Backdrop open={open}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
};
export default LoadingOverlay;