import axios from "../index";
import {useMutation} from "react-query";
import authService from "../../components/auth/authService";

const useLogin = () => {
    return useMutation(async({email, password}) => {
        try {
            return axios.post(`/auth`, {email, password})
                .then((response) => response.data);
        } catch (err) {
            throw err;
        }
    })
}

const useLogout = () => {
    return useMutation(async() => {
        return new Promise((resolve, reject) => {
            authService.logout()
            authService.loginRedirect()
            resolve()
        })
    })
}

export const mutations = {
    useLogin,
    useLogout
}