import React from 'react'
import {mutations} from "../../api/query/school";
import BackendContent from "../../layout/BackendContent";
import {Toolbar} from "../../layout/toolbar/Toolbar";
import FeedbackButton from "../../components/form/FeedbackButton";
import ActionButton from "../../layout/ActionButton";
import {useParams} from "react-router-dom";
import {Grid} from "@material-ui/core";
import Form from "./Form";

export const CreateView = () => {
    const {electionId} = useParams()
    const {mutate: mutateCreate, isLoading: creating} = mutations.useCreate()

    const backLink = `/election/${electionId}/school`

    return <BackendContent>

        <Toolbar title={'Neue Schule erstellen'}>

            <FeedbackButton form="schoolForm" type="submit" pending={creating}>
                speichern
            </FeedbackButton>

            <ActionButton to={backLink}>
                Zur Liste
            </ActionButton>

        </Toolbar>

        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Form
                    onSubmit={(data) => {
                        mutateCreate({electionId, data})
                    }}
                    backLink={backLink}
                    pending={creating}
                />
            </Grid>
        </Grid>

    </BackendContent>;
}

export default CreateView