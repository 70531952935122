import React from 'react';
import {Route, Switch} from "react-router-dom";

import MiddleContent from "../layout/templates/MiddleContent";
import Error404 from "./system/Error404";
import LayoutRoute from "../layout/router/LayoutRoute";

import Login from "./auth/Login";
import {AuthProvider} from "../components/auth/AuthProvider";
import ElectionRouting from "../pages/election/Routing"
import CandidateRouting from "./candidate/Routing";

import SchoolRouting from "./school/Routing";
import DistrictRouting from "./district/Routing";
import VotingRouting from "./voting/Routing";
import TanCodeRouting from "./tanCode/Routing";
import StatisticsRouting from "./statistics/Routing";
import SnippetRouting from "./snippet/Routing";
import Imprint from '../layout/Imprint'
import Privacy from '../layout/Privacy'

const Routing = () => {

    return (
        <>
            <Switch>

                <LayoutRoute layout={MiddleContent} exact path={'/'} component={Login}/>
                <LayoutRoute layout={MiddleContent} exact path={'/imprint'} component={Imprint} />
                <LayoutRoute layout={MiddleContent} exact path={'/privacy'} component={Privacy} />

                <AuthProvider>
                    <Switch>
                        <Route path={'/election/:electionId/candidate'} component={CandidateRouting} />
                        <Route path={'/election/:electionId/school'} component={SchoolRouting} />
                        <Route path={'/election/:electionId/snippet'} component={SnippetRouting} />
                        <Route path={'/election/:electionId/district'} component={DistrictRouting} />
                        <Route path={'/election/:electionId/voting'} component={VotingRouting} />
                        <Route path={'/election/:electionId/tanCode'} component={TanCodeRouting} />
                        <Route path={'/election/:electionId/statistics'} component={StatisticsRouting} />

                        <Route path={'/election'} component={ElectionRouting}/>
                    </Switch>

                </AuthProvider>

                <LayoutRoute path="*" layout={MiddleContent} component={Error404}/>
            </Switch>
        </>
    )
};
export default Routing;