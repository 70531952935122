import React from 'react';

import styled from "styled-components";
import {Button} from '../../layout/StyledComponent';
import CircularProgress from "@material-ui/core/CircularProgress";

const StyledCircularProgress = styled(CircularProgress)`
    margin-left: 10px;
    color: #ffffff;
`;

const FeedbackButton = ({pending, children, ProgressProps = {}, disabled = false, ...rest}) => {
    return <Button
            disabled={pending || disabled}
            variant={'contained'}
            color={"primary"}
            fullWidth={false}
            {...rest}
        >
            {children}
            {pending &&<StyledCircularProgress size={20} {...ProgressProps} />}
    </Button>
};

export default FeedbackButton;